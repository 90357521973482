import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';

import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      request: null,
      type: null,
      items: [],
      placeholder: 'Loading',
      offset: 0,
      initialOffset: this.data.offset ? parseInt(this.data.offset, 10) : 0,
      skeletonItems: this.data.infinite_scroll
        ? parseInt(this.data.posts_per_page, 10)
        : this.data.archive_type === 'posts'
          ? this.data.posts.length
          : 12,
      groups: [],
      loadMore: false,
    };
  },
  mounted() {
    if (!this.data.thumbs) {
      this.setRequest();
    } else if (this.data.posts && this.data.posts.length > 0) {
      this.data.posts.forEach((item) => {
        const { type } = item;
        const postType = type !== 'galaxy_express' ? `${type}s` : type;
        this.$store.commit('ADD_ITEM', {
          type: postType,
          item,
          lang: 'default',
        });
      });
    }

    this.loadItems().then(() => {
      if (this.data.infinite_scroll) {
        // this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = !this.data.thumbs
        ? await this.$store.dispatch('getItems', this.request)
        : this.data.posts;
      if (items) {
        this.items = [...this.items, ...items];

        this.loadMore = this.data.infinite_scroll
          && this.items.length
            >= parseInt(this.data.posts_per_scroll, 10) + this.offset;
      }

      if (this.data.groupBy) {
        this.groups = [];
        const groups = groupBy(this.items, (item) => item.acf.show.id);
        Object.keys(groups).forEach((key) => {
          const group = {
            id: key,
            name: groups[key][0].acf.show.title.rendered,
            link: groups[key][0].acf.show.link,
            items: groups[key],
          };

          this.groups.push(group);
        });

        this.groups = orderBy(this.groups, (g) => g.items[0].acf.show.date, [
          'desc',
        ]);
      }

      return items;
    },
    setRequest() {
      let postType = null;
      const include = [];
      let types = null;

      this.type = this.data.post_type;
      const { post_type, categories } = this.data;
      if (!this.data.thumbs) {
        postType = post_type !== 'galaxy_express' ? `${post_type}s` : post_type;
        if (categories) {
          types = Array.isArray(categories) ? categories : categories;
        }
      }
      const offset = this.type !== 'posts' ? this.offset : 0;

      this.request = {
        type: postType,
        params: {
          types,
          include: include.length > 0 ? include : null,
          per_page: this.data.infinite_scroll
            ? parseInt(this.data.posts_per_scroll, 10) || 12
            : parseInt(this.data.posts_per_page, 10) || 100,
          offset: offset + this.initialOffset,
          orderby: 'date',
          order: this.data.order ? this.data.order : null,
          lang: this.$store.state.lang,
        },
      };

      if (this.data.post_type === 'project') {
        // eslint-disable-next-line no-underscore-dangle
        this.request.params._fields = 'id,slug,title,name,link,permalink,gds_featured_image,featured_media,date_format,preview_thumb,type,status';
        this.request.params.lite = true;
      }
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget(
        'infinite-scroll',
        this.$el.querySelector('.infinite'),
      );
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      if (
        this.type === 'posts'
        && this.offset + (parseInt(this.data.posts_per_scroll, 10) || 12)
          >= this.data[this.type].length
      ) {
        this.loadMore = false;
        medusa.ref.removeTarget('infinite-scroll');
        return;
      }
      this.offset += parseInt(this.data.posts_per_scroll, 10) || 12;
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          this.loadMore = false;
          medusa.ref.removeTarget('infinite-scroll');
        } else if (
          window.pageYOffset + window.innerHeight
          === document.body.offsetHeight
        ) {
          this.requestOffset();
        }
      });
    },
  },
};
