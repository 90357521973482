var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:['modal', `modal-theme--${_vm.theme}`, 'overlay'],attrs:{"align":"center","tabindex":_vm.show ? 0 : -1}},[(_vm.data)?_c('Spacer',{class:['modal-wrapper'],attrs:{"y":"xxs","x":"xs"}},[(!_vm.data.mandatory)?_c('Flex',{attrs:{"justify":"end"}},[_c('Cta',{staticClass:"close",attrs:{"data":{
            title: 'Chiudi',
            fn: _vm.close,
          },"theme":"button"}})],1):_vm._e(),(_vm.data.type === 'video')?[_c('Video',{ref:"video",attrs:{"data":{
            embed: true,
            url: _vm.data.url,
          }}})]:_vm._e(),(_vm.data.type === 'content')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Content',{attrs:{"data":{
              title: _vm.data.content.title,
              rich_text: _vm.data.content.richtext,
              cta: _vm.data.content.cta,
            }}})],1)]:_vm._e(),(_vm.data.type === 'dialog')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Grid',{attrs:{"col":"12"}},[_c('Title',{attrs:{"data":{
                value: _vm.data.content.title,
              }}}),_c('Richtext',{attrs:{"data":{
                value: _vm.data.content.richtext,
              }}}),_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{attrs:{"data":{
                  fn: _vm.dialogFn,
                  title: _vm.data.content.cta.title,
                },"theme":"button"}})],1)],1)],1)]:_vm._e(),(_vm.data.type === 'share')?[_c('Spacer',{attrs:{"y":"xs"}},[_c('Grid',{attrs:{"col":"3"}},[_c('a',{staticClass:"share-link fb",attrs:{"href":`https://facebook.com/dialog/share?display=popup&href=${encodeURIComponent(
                  _vm.data.link
                )}&app_id=2031953930297224`,"target":"_blank"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/fb.svg`)}}),_c('span',[_vm._v("Facebook")])],1),_c('a',{staticClass:"share-link tw",attrs:{"href":`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  _vm.data.link
                )}&text=${encodeURIComponent(_vm.data.title)}&via=RadioraheemMi`,"target":"_blank"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/tw.svg`)}}),_c('span',[_vm._v("Twitter")])],1),_c('a',{staticClass:"share-link wa",attrs:{"href":`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  _vm.data.link
                )}`,"data-action":"share/whatsapp/share","target":"_blank"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/wa.svg`)}}),_c('span',[_vm._v("Whatsapp")])],1),_c('div',{staticClass:"share-link link",on:{"click":_vm.clipboard}},[_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/link.svg`)}}),_c('span',[_vm._v(_vm._s(_vm.copy))])],1)])],1)]:_vm._e()],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }