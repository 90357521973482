<template>
  <header v-show="!$route.meta.hideHeader">
    <router-link
      to="/"
      class="logo u-hidden--until-s"
      align="center"
      justify="center"
    >
      <inline-svg
        :src="require(`@/assets/svg/logo.svg`)"
      />
    </router-link>

    <Flex
      class="bar"
      align="center"
      justify="space-between"
    >
      <Cta
        :data="{
          title: overlayStatus('menu') ? 'Chiudi' : 'Menu',
          size: 'xxs',
          fn: toggleMenu
        }"
      />
      <Cta
        :data="{
          title: overlayStatus('search') ? 'Chiudi' : 'Cerca',
          size: 'xxs',
          fn: toggleSearch
        }"
      />

      <div class="heading">
        <Cta
          :data="{
            title: 'Radio Raheem',
            size: 's',
            url: '/',
          }"
        />
      </div>
    </Flex>

    <transition
      :css="false"
      @enter="enterOverlay"
      @leave="leaveOverlay"
    >
      <div
        v-if="overlayStatus('menu') || overlayStatus('search')"
        class="header-wrapper wrapper"
      >
        <transition
          :css="false"
          mode="out-in"
          @enter="enterChildOverlay"
          @leave="leave"
        >
          <component
            :is="overlayStatus('search') ? 'Search' : 'Menu'"
            :ref="overlayStatus('search') ? 'search' : 'menu'"
          />
        </transition>
      </div>
    </transition>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { enterOverlay, leaveOverlay, enterChildOverlay } from '@/assets/js/transitions';

import Menu from '@/components/ui/menu';
import Search from '@/components/ui/search';
import Cta from '@/components/typo/cta';

export default {
  name: 'Header',
  components: {
    Menu,
    Cta,
    Search,
  },
  data() {
    return {
      enterOverlay,
      leaveOverlay,
      enterChildOverlay,
    };
  },
  computed: {
    ...mapGetters(['overlayStatus', 'overlay']),
  },
  methods: {
    toggleMenu() {
      this.$store.commit('TOGGLE_OVERLAY', 'menu');
    },
    toggleSearch() {
      this.$store.commit('TOGGLE_OVERLAY', 'search');
    },
    leave(el, done) {
      done();
    },
    closeOverlay() {
      if (this.overlay) {
        this.$store.commit('TOGGLE_OVERLAY', this.overlay);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: relative;

  .bar {
    background: var(--white);

    & > * {
      position: relative;
      transform: translateY(-2px);

      @include mq(s) {
        transform: translateY(-1px);
      }
    }

  }

  .heading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) translateY(-1px);
    text-align: center;

    @include mq(s) {
      transform: translate3d(-50%, -50%, 0) translateY(-2px);
    }
  }

  .logo {
    position: fixed;
    top: 12px;
    left: 10px;
    width: 35px;

    ::v-deep path {
      fill: var(--white);

      @at-root [data-theme="light"] & {
        fill: var(--black);
      }
    }
  }

  .header-wrapper {
    overflow: hidden;
    width: 100%;
    display: flex;
    background: var(--white);

    & > * {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}
</style>
