<template>
  <div class="audio-player">
    <audio
      ref="player"
      preload="none"
      cross-origin
    >
      <source
        :src="data.src"
        type="audio/mp3"
      >
    </audio>
  </div>
</template>

<script>
// import Plyr from 'plyr';
import { mapGetters } from 'vuex';

export default {
  name: 'AudioPlayer',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  computed: {
    ...mapGetters(['audio', 'isMuted', 'embed']),
  },
  watch: {
    isMuted(val) {
      this.$refs.player.muted = val;
    },
    audio(val) {
      if (val) {
        if (this.embed) {
          this.$store.commit('SET_EMBED', false);
        }
        this.$refs.player.play();
      } else {
        this.$refs.player.pause();
      }
    },
  },
  mounted() {
    // this.player = new Plyr(this.$refs.player);
  },
  methods: {
    isReady() {
      this.$nextTick(() => {
        this.$refs.player.play();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-player {
  display: none;
}
</style>
