<template>
  <div
    :class="['switch', !status ? 'switch-active' : false]"
    :data-on="on"
    :data-off="off"
    @click="setToggle(!status)"
  >
    <span class="slider" />
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    on: {
      type: String,
      required: true,
    },
    off: {
      type: String,
      required: true,
    },
    initialState: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    watchFromProps: {
      type: Boolean,
      default: () => {},
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      status: true,
    };
  },
  watch: {
    watchFromProps(val) {
      this.status = val;
    },
  },
  created() {
    this.status = this.initialState;
  },
  methods: {
    setToggle(val) {
      this.status = val;
      this.callback(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 29px;
  height: 15px;
  cursor: pointer;

  &-disable {
    opacity: 0.5;
    cursor: default;
  }

  &::after {
    content: attr(data-on);
    position: absolute;
    @extend %typo--xxs;
    font-size: 10px;
    line-height: 14px;
    right: 5px;
    opacity: 1;
    transition: opacity 0.1s;
  }

  &::before {
    content: attr(data-off);
    position: absolute;
    @extend %typo--xxs;
    font-size: 10px;
    line-height: 14px;
    left: 5px;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &-active {
    &::after {
      opacity: 0;
    }
    &::before {
      opacity: 1;
    }
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid var(--grey-d);
  border-radius: 20px;

  &::before {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    left: 3px;
    top: 2px;
    background-color: currentColor;
    transition: transform 0.1s ease-out;
    border-radius: 50%;
  }
}

.switch-active .slider:before {
  transform: translateX(12px);
}
</style>
