import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import wp from './modules/wp';

// eslint-disable-next-line no-undef
const { state } = __VUE_WORDPRESS__;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentPost: null,
    lang: state.i18n ? state.i18n.default : 'default',
    transition: true,
    snackbar: {
      content: null,
      active: false,
    },
    overlay: false,
    singleOverlay: false,
    embed: false,
    audio: false,
    video: false,
    live: false,
    now: false,
    muted: false,
  },
  mutations,
  getters,
  actions,
  modules: {
    wp,
    // wc,
    // auth,
  },
});
