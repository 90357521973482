<template>
  <transition
    name="notification"
    appear
  >
    <div
      v-if="showNotification"
      class="popup"
    >
      <div class="popup-wrapper">
        <Cta
          :data="{
            title: 'Chiudi',
            fn: closePopup,
          }"
          theme="button"
          class="popup-close"
        />
        <Title
          v-if="data.title"
          :data="{ value: data.title, size: 'xxs' }"
        />
        <Richtext
          v-if="data.text"
          :data="{ value: data.text, size: 'xxs' }"
          :class="!data.title || data.title === '' ? 'no-title' : false"
        />
        <Cta
          v-if="data.cta && data.type !== 'pwa'"
          :data="{ ...data.cta, fn: closePopup }"
          theme="rounded-full"
          class="popup-cta"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Notification',
  components: {
    Title,
    Richtext,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNotification: !Cookies.get(`radioraheem-${this.data.id}`),
    };
  },
  computed: {
    ...mapGetters(['options']),
  },

  methods: {
    closePopup() {
      Cookies.set(`radioraheem-${this.data.id}`, '1', {
        expires:
          this.data.expires && parseInt(this.data.expires, 10) > 0
            ? parseInt(this.data.expires, 10)
            : 1,
      });
      this.showNotification = false;
    },
    fnPwa() {
      this.closePopup();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: relative;
  background: var(--white);
  color: var(--black);
  width: 100%;
  --color: var(--white);
  --bg: var(--black);

  @include mq(s) {
    width: 380px;
  }

  .title {
    margin-bottom: var(--spacer-s);
    max-width: calc(100% - 60px);
  }

  .wysiwyg {
    text-transform: none;
    &.no-title {
      max-width: calc(100% - 60px);
    }
  }

  &-close {
    position: absolute;
    right: var(--spacer-xs);
    top: var(--spacer-xxs);
  }

  &-wrapper {
    position: relative;
    padding: var(--spacer-xs) var(--spacer-xs);
  }

  &-cta {
    margin-top: var(--spacer-s);
  }
}

.notification-enter-active {
  transition: all 0.8s $ease-custom;
}
.notification-leave-active {
  transition: all 0.3s $ease-custom;
}
.notification-leave-to {
  opacity: 0;

  transform: translateY(10px);
}

.notification-enter {
  transform: translateY(100%);

  @include mq(m) {
    transform: translateY(100%) translateY(10px);
  }
}
</style>

<style lang="scss">
.icon-ios-share {
  display: inline-block;
  width: 14px;
  height: 17px;
  background-image: url("/wp-content/themes/gds-vue/static/img/share.png");
  background-size: 100%;
  margin: 0 2px;
  vertical-align: bottom;
}
</style>
