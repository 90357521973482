var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{class:['content', _vm.data.theme ? `content--${_vm.data.theme}` : false],attrs:{"x":_vm.noPadding ? undefined : 'xs'}},[(_vm.data.theme === 'hero' || _vm.data.theme === 'banner')?_c('div',{staticClass:"separator--row"}):_vm._e(),_c('div',{staticClass:"content-wrapper"},[_c('div',[(_vm.data.label && _vm.data.label !== '')?_c('Label',{class:[
          _vm.data.theme === 'banner' ? 'title-content t-uppercase' : false,
        ],attrs:{"data":{
          value: _vm.data.label,
          tag: 'h3',
          size: _vm.data.theme === 'banner' ? 'l' : _vm.data.label_size || 's',
        }}}):_vm._e(),(_vm.data.title && _vm.data.title !== '' && _vm.data.theme !== 'banner')?_c('Title',{class:[_vm.data.theme === 'hero' ? 't-uppercase' : false],attrs:{"data":{
          value: _vm.data.title,
          tag: 'h2',
          size: 'l',
        }}}):_vm._e(),(_vm.data.image && _vm.data.theme === 'banner')?_c(_vm.data.cta
            ? _vm.data.cta.target === '_blank'
              ? 'a'
              : 'router-link'
            : 'div',{tag:"component",staticClass:"thumb-cover",attrs:{"to":_vm.data.cta && _vm.data.cta.target !== '_blank'
            ? _vm.$relativeUrl(_vm.data.cta.url)
            : false,"href":_vm.data.cta && _vm.data.cta.target === '_blank' ? _vm.data.cta.url : false,"target":_vm.data.cta && _vm.data.cta.target ? _vm.data.cta.target : false}},[_c('Figure',{attrs:{"data":{
            ..._vm.data.image,
            ratio: false,
            relative: true,
          },"disable-caption":""}})],1):_vm._e(),_c('div',{class:_vm.data.theme === 'banner' && _vm.data.image ? 'thumb-content' : false},[(_vm.data.image && _vm.data.theme === 'banner')?_c(_vm.data.cta
              ? _vm.data.cta.target === '_blank'
                ? 'a'
                : 'router-link'
              : 'div',{tag:"component",staticClass:"thumb-cover",attrs:{"to":_vm.data.cta && _vm.data.cta.target !== '_blank'
              ? _vm.$relativeUrl(_vm.data.cta.url)
              : false,"href":_vm.data.cta && _vm.data.cta.target === '_blank' ? _vm.data.cta.url : false,"target":_vm.data.cta && _vm.data.cta.target ? _vm.data.cta.target : false}},[(_vm.data.title && _vm.data.title !== '' && _vm.data.theme === 'banner')?_c('Title',{staticClass:"thumb-title",attrs:{"data":{
              value: _vm.data.title,
              size: !_vm.$mq.isMobile ? 'xl' : 'l',
            }}}):_vm._e()],1):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('RichText',{staticClass:"thumb-text",attrs:{"data":{
            value: _vm.data.rich_text,
            size:
              _vm.data.theme === 'banner'
                ? _vm.$mq.isMobile
                  ? 's'
                  : 'm'
                : _vm.data.rich_text_size
                  ? _vm.data.rich_text_size
                  : _vm.theme === 'about'
                    ? 'l'
                    : 'm',
          }}}):_vm._e()],1)],1),(_vm.data.cta)?_c('Spacer',{staticClass:"cta-wrapper",attrs:{"top":_vm.data.theme === 'hero' ? undefined : { default: 'm', m: 'xl' }}},[_c('Cta',{attrs:{"data":{
          ..._vm.data.cta,
          size: _vm.data.theme === 'hero' ? 'xs' : 's',
        },"theme":"button"}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }