var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:_vm.isPost ? 'single--post' : false},[(!_vm.post.gds_featured_image || _vm.post.type === 'partner')?_c('Spacer',{attrs:{"top":_vm.liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'}}):_vm._e(),(_vm.post.gds_featured_image && _vm.post.type !== 'partner')?_c('div',{class:['cover', _vm.isProject ? 'cover--project' : false]},[_c('Figure',{attrs:{"data":{
        ..._vm.post.gds_featured_image,
        mobile: _vm.post.acf.featured_image_mobile,
        align: _vm.post.acf.align_cover,
        fit: _vm.post.acf.fit_cover,
      },"disable-caption":""}})],1):_vm._e(),_c('Spacer',{attrs:{"all":"xs"}},[(_vm.breadcrumbs)?_c('Spacer',{staticClass:"breadcrumbs",attrs:{"bottom":"s"}},[_c('Cta',{attrs:{"data":_vm.breadcrumbs}})],1):_vm._e(),_c('Spacer',{staticClass:"single-header",attrs:{"bottom":"xxs"}},[_c('div',[_c('Title',{attrs:{"data":{
            value: _vm.post.title.rendered,
            size: _vm.isPost || _vm.isEvent ? 'xl' : 'l',
          }}}),(_vm.post.acf && _vm.post.acf.data && _vm.isEvent)?_c('Label',{staticClass:"meta-event",attrs:{"data":{ value: _vm.post.acf.data, size: 'm', tag: 'time' }}}):_vm._e()],1),_c('Cta',{staticClass:"share",attrs:{"data":{
          title: 'Condividi',
          fn: _vm.openShare,
          size: 'xxs',
        },"theme":"button"}})],1),(_vm.post.content)?_c('RichText',{staticClass:"excerpt",attrs:{"data":{
        value: _vm.post.content.rendered,
        size: _vm.isPost || _vm.isEvent ? 'm' : 'm',
      }}}):_vm._e()],1),(_vm.post.acf.section)?_c('Spacer',{attrs:{"top":"xxl"}}):_vm._e(),_vm._l((_vm.post.acf.section),function(block,index){return _c(_vm.components.get(block.acf_fc_layout),{key:index,tag:"component",class:['section spacer--padding--b--xl2'],attrs:{"data":block}})}),(_vm.archive && !_vm.post.acf.section)?_c('Spacer',{attrs:{"top":"xxl"}}):_vm._e(),_c('keep-alive',[(_vm.archive && !_vm.archive.grouped)?_c('Archive',{key:_vm.archiveKey,attrs:{"data":{
        layout: _vm.archive.thumb ? _vm.archive.thumb : 'thumb_medium',
        categories: _vm.archive.categories
          ? `${_vm.archive.categories}:${_vm.post.id}`
          : null,
        cta: '',
        infinite_scroll: true,
        post_type: _vm.archive.post_type ? _vm.archive.post_type : 'episode',
        posts_per_scroll: 24,
        thumbs: false,
        title: !_vm.archive.grouped
          ? _vm.archive.title
            ? _vm.archive.title
            : 'Episodi'
          : false,
        groupBy: _vm.archive.grouped ? _vm.archive.grouped : null,
      }}}):_vm._e()],1),(_vm.archive && _vm.archive.grouped && _vm.archive.guest)?_c('Grid',{attrs:{"col":"12"}},[_vm._l((_vm.groups),function(group){return _c('Archive',{key:group.id,attrs:{"data":{
        layout: 'thumb_medium',
        categories: `show:${group.id}`,
        cta: {
          title: 'Vai allo show',
          url: group.link,
        },
        posts_per_page: _vm.groups.length >= 4 ? 4 : 8,
        post_type: 'episode',
        thumbs: false,
        title: group.title.rendered,
        groups: true,
      }}})}),_c('keep-alive',[(_vm.archive && _vm.archive.guest)?_c('Archive',{key:_vm.archiveKey,attrs:{"data":{
          layout: _vm.archive.thumb ? _vm.archive.thumb : 'thumb_medium',
          categories: `guest:${_vm.post.id}`,
          cta: '',
          infinite_scroll: true,
          post_type: 'episode',
          posts_per_scroll: 24,
          thumbs: false,
          title: false,
          groupBy: true,
          groups: true,
        }}}):_vm._e()],1)],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }