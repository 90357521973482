var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search"},[_c('Grid',{attrs:{"col":"12","row-gap":"0"}},[_c('div',{staticClass:"search-header"},[_c('Flex',{staticClass:"search-input bar",attrs:{"align":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.s),expression:"request.s"}],attrs:{"type":"text","name":"search","placeholder":"Cerca qualcosa...","spellcheck":"false"},domProps:{"value":(_vm.request.s)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.request, "s", $event.target.value)},_vm.checkFilters]}})]),_c('transition',{attrs:{"name":"accordion"},on:{"enter":_vm.start,"after-enter":_vm.end,"before-leave":_vm.start,"after-leave":_vm.end}},[_c('Grid',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"discover",staticClass:"discover",attrs:{"col":"12","row-gap":"l"}},[_c('Spacer',{staticClass:"t-center t-uppercase",attrs:{"x":"xs","bottom":"xs","top":"l"}},[_c('Grid',{attrs:{"col":"12","row-gap":"xs"}},[_c('Label',{attrs:{"data":{ value: 'Categoria', size: 'xxs' }}}),_c('Flex',{attrs:{"justify":"center","gap":""}},_vm._l((_vm.categories),function(category){return _c('Flex',{key:category.slug,class:[
                    'show-category',
                    _vm.request.category === category.slug
                      ? 'show-category-active'
                      : false,
                  ],attrs:{"align":"center","justify":"center"},nativeOn:{"click":function($event){return _vm.setShowCategory(category.slug)}}},[_c('Label',{attrs:{"data":{ value: category.name, size: 'xs' }}})],1)}),1)],1)],1),_c('Spacer',{staticClass:"t-center",attrs:{"x":"xs","top":"xs","bottom":"l"}},[_c('Grid',{attrs:{"col":"4"}},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter.id,class:[
                  'filter',
                  filter.options.length === 0
                    ? 'not-active'
                    : false,
                ]},[_c('Label',{attrs:{"data":{ value: filter.name, size: 'xxs' }}}),_c('div',{staticClass:"select-wrapper"},[(filter.options.length > 0)?_c('Select',{key:filter.slug === 'subgenre'
                        ? _vm.request.genre
                        : filter.slug === 'genre'
                          ? _vm.request.category
                          : filter.slug,attrs:{"options":filter.options,"map":filter.map,"callback":filter.fn,"initial-slide":filter.slug !== 'year'
                        ? _vm.initialSlide(filter)
                        : 0}}):_vm._e()],1)],1)}),0)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"separator--row separator--row--light"})],1),_c('div',{ref:"results",staticClass:"results"},[_c('Spacer',{attrs:{"x":"xs","bottom":"xs","top":"xxs"}},[(_vm.results && _vm.results.length === 0 && !_vm.found)?_c('div',[_vm._v(" Nessun risultato ")]):(_vm.results && _vm.results.length > 0)?_c('div',[_c('Spacer',{attrs:{"bottom":"xs","use-margin":""}},[_c('Label',{attrs:{"data":{ value: 'Risultati', size: 'xxs' }}})],1),_c('Grid',{attrs:{"col":"12","row-gap":"xs"}},_vm._l((_vm.results),function(thumb){return _c('ThumbResult',{key:thumb.id,attrs:{"data":thumb}})}),1)],1):_c('div',[_vm._v(" Sto cercando ")])]),(
          _vm.totalResults && _vm.results && _vm.results.length > 0 && _vm.searchUrl
        )?_c('div',{staticClass:"results-total"},[_c('Cta',{attrs:{"data":{
            title: `Visualizza tutti (${_vm.totalResults})`,
            url: _vm.searchUrl,
          },"theme":"rounded-full"}})],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }