/* eslint-disable */
'use strict';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import Vue from 'vue';

module.exports = class Asteroid {
  constructor(container, _startTexturePath, rand, _audioEffect) {
    if (_startTexturePath != null) {
      this.startTexturePath = _startTexturePath;
    }
    if (_audioEffect != null) {
      this.audioEffect = _audioEffect;
    } else {
      this.audioEffect = false;
    }

    this.container = container || document.body;

    // Variables
    this.index = rand;
    this.asteroidModels;
    this.asteroidMesh;
    this.asteroidMat;
    this.albedoTex;
    this.noiseTex1;
    this.noiseTex2;
    this.mousePos = { x: 0, y: 0 };
    this.smoothMousePos = { x: 0, y: 0 };
    this.bass = 0;
    this.treble = 0;
    this.bassSmoothed = 0;
    this.trebleSmoothed = 0;
    this.maxBass = 0;
    this.maxTreble = 0;
    this.loadingCompleted = false;
    this.audioIsReady = false;
    this.musicDataArray;

    // Three
    this.webglCanvas = document.createElement('canvas');
    this.container.appendChild(this.webglCanvas);
    const { width, height } = this.container.getBoundingClientRect();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0, 0, 0);
    this.camera = new THREE.PerspectiveCamera(800, width / height, 0.5, 100);
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 2.3;

    this.scene.add(this.camera);
    this.webglRenderer = new THREE.WebGLRenderer({
      canvas: this.webglCanvas,
    });
    this.webglRenderer.setSize(width, height);
    this.webglRenderer.setPixelRatio(window.devicePixelRatio);
    this.clock = new THREE.Clock();

    // this.resize();

    // Audio
    if (this.audioEffect) {
      this.context = new AudioContext();
      this.audio = new Audio();
      // audio.src = "https://streams.radio.co/s63f12ebcb/listen";
      this.audio.src = 'audio/movingon.mp3';
      const that = this;
      this.audio.addEventListener(
        'canplay',
        () => {
          that.audioIsReady = true;
        },
        true
      );
      this.source = this.context.createMediaElementSource(this.audio);
      this.source.connect(this.context.destination);
      this.analyser = this.context.createAnalyser();
      this.analyser.fftSize = 512;
      const bufferLength = this.analyser.frequencyBinCount;
      this.musicDataArray = new Uint8Array(bufferLength);
      // this.source.disconnect();
      this.source.connect(this.analyser);
    }
  }
  // ## DESTROY AUDIO ##
  destroyAudio() {
    this.source.disconnect();
  }

  load() {
    const that = this;

    const manager = new THREE.LoadingManager();
    manager.onProgress = function (item, loaded, total) {};
    manager.onLoad = function () {
      that.loadShader();
    };

    // 3D
    const gltfLoader = new GLTFLoader(manager);
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/jsm/libs/draco/');
    gltfLoader.setDRACOLoader(dracoLoader);
    gltfLoader.load(
      `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/3d/asteroid_${this.index}.glb`,
      (glb) => {
        that.asteroidModels = glb.scene.children[0];
      }
    );

    // Texture
    const texLoader = new THREE.TextureLoader(manager);
    texLoader.load(that.startTexturePath, (img) => {
      that.albedoTex = img;
    });

    if (that.audioEffect) {
      texLoader.load('img/noiseTex1.png', (img) => {
        that.noiseTex1 = img;
        that.noiseTex1.wrapS = that.noiseTex1.wrapT = THREE.RepeatWrapping;
      });
      texLoader.load('img/noiseTex2.png', (img) => {
        that.noiseTex2 = img;
        that.noiseTex2.wrapS = that.noiseTex2.wrapT = THREE.RepeatWrapping;
      });
    }
  }

  loadShader() {
    const that = this;
    ShaderLoader(
      `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/shader/asteroid.vert`,
      `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/shader/asteroid.frag`,
      (vertex, fragment) => {
        that.asteroidMat = new THREE.ShaderMaterial({
          vertexShader: vertex,
          fragmentShader: fragment,
          uniforms: {
            time: {
              value: 1.0,
            },
            scale: {
              value: 3.0,
            },
            noiseTex1: {
              value: that.noiseTex1,
            },
            noiseTex2: {
              value: that.noiseTex2,
            },
            albedoTex: {
              value: that.albedoTex,
            },
            bass: {
              value: 0,
            },
            mid: {
              value: 0,
            },
            treble: {
              value: 0,
            },
          },
        });

        that.loadingCompleted = true;
        Vue.prototype.$bus.$emit('ASTEROID_READY');
      }
    );
  }

  // ## INIT ##
  init() {
    if (!this.loadingCompleted || (this.audioEffect && !this.audioIsReady)) {
      return;
    }

    // const randomIndex = Math.floor(Math.random() * this.asteroidModels.length);
    this.asteroidMesh = this.asteroidModels;

    this.asteroidMesh.material = this.asteroidMat;
    this.scene.add(this.asteroidMesh);

    if (this.audioEffect) {
      this.context.resume();
      this.audio.play();
    }

    Vue.prototype.$bus.$on('windowResized', this.resize.bind(this));
    Vue.prototype.$bus.$on('initApp', this.resize.bind(this));

    this.animate();
  }

  // ## UPDATE TEXTURES ##
  updateTexture(_path) {
    const that = this;
    const texLoader = new THREE.TextureLoader();
    texLoader.load(_path, (img) => {
      that.albedoTex = img;
      // albedoTex.wrapS = albedoTex.wrapT = THREE.RepeatWrapping;
      that.asteroidMat.uniforms.albedoTex.value = img;
    });
  }

  // ## RESIZE ##
  resize() {
    const { width, height } = this.container.getBoundingClientRect();

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.webglCanvas.width = width;
    this.webglCanvas.height = height;
    this.webglRenderer.setSize(width, height);
    this.webglRenderer.setPixelRatio(window.devicePixelRatio);
  }

  // ## ANIMATE ##
  animate() {
    const elapsedTime = this.clock.getElapsedTime();
    this.smoothMousePos = {
      x: lerp(this.smoothMousePos.x, this.mousePos.x, 0.02),
      y: lerp(this.smoothMousePos.y, this.mousePos.y, 0.02),
    };

    this.asteroidMesh.rotation.y = 0.2 * elapsedTime + this.smoothMousePos.x * 3.0;
    this.asteroidMesh.rotation.x = elapsedTime * 0.02 + this.smoothMousePos.y;
    this.asteroidMesh.rotation.z = elapsedTime * 0.05;

    if (this.audioEffect) {
      this.applyAudioEffect();
    }

    // Render
    this.webglRenderer.render(this.scene, this.camera);
  }

  applyAudioEffect() {
    const elapsedTime = this.clock.getElapsedTime();

    this.analyser.getByteFrequencyData(this.musicDataArray);
    const lowerHalfArray = this.musicDataArray.slice(0, this.musicDataArray.length / 8 - 1);
    const upperHalfArray = this.musicDataArray.slice(
      this.musicDataArray.length / 8 - 1,
      this.musicDataArray.length - 1
    );
    // var lowerMax = max(lowerHalfArray);
    // var upperMax = max(upperHalfArray);
    const lowerAvg = avg(lowerHalfArray);
    const upperAvg = avg(upperHalfArray);
    // var lowerMaxFr = lowerMax / lowerHalfArray.length;
    // var lowerAvgFr = lowerAvg / lowerHalfArray.length;
    // var upperAvgFr = upperAvg / upperHalfArray.length;

    if (lowerAvg > this.maxBass) this.maxBass = lowerAvg;
    this.maxBass = Math.max(this.maxBass, 1);
    this.bass = lowerAvg / this.maxBass;
    if (upperAvg > this.maxTreble) this.maxTreble = upperAvg;
    this.maxTreble = Math.max(this.maxTreble, 1);
    this.treble = upperAvg / this.maxTreble;

    this.bassSmoothed = lerp(this.bassSmoothed, this.bass, 0.4);
    this.trebleSmoothed = lerp(this.trebleSmoothed, this.treble, 0.05);

    this.asteroidMat.uniforms.bass.value = Math.abs(this.bass - this.bassSmoothed) * 20.0 * this.bassSmoothed;
    this.asteroidMat.uniforms.treble.value =
      (Math.abs(this.treble - this.trebleSmoothed) + this.treble * 0.2) * this.trebleSmoothed;
    this.asteroidMat.uniforms.time.value = elapsedTime * 0.31;
  }

  destroy() {
    this.scene.clear();
    if (this.audioEffect) this.destroyAudio();
  }

  handleMouseMove(event) {
    let eventDoc;
    let doc;
    let body;
    event = event || window.event; // IE-ism

    if (event.pageX == null && event.clientX != null) {
      eventDoc = (event.target && event.target.ownerDocument) || document;
      doc = eventDoc.documentElement;
      body = eventDoc.body;

      event.pageX =
        event.clientX +
        ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
        ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
      event.pageY =
        event.clientY +
        ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
        ((doc && doc.clientTop) || (body && body.clientTop) || 0);
    }

    this.mousePos = {
      x: event.pageX / window.innerWidth,
      y: event.pageY / window.innerHeight,
    };
  }
};
// HELP
function avg(arr) {
  const total = arr.reduce((sum, b) => sum + b);
  return total / arr.length;
}
function max(arr) {
  return arr.reduce((a, b) => Math.max(a, b));
}
function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}
function ShaderLoader(vertex_url, fragment_url, onLoad, loadingManager, onProgress, onError) {
  const vertex_loader = new THREE.FileLoader(loadingManager);
  vertex_loader.setResponseType('text');
  vertex_loader.load(
    vertex_url,
    (vertex_text) => {
      const fragment_loader = new THREE.FileLoader(loadingManager);
      fragment_loader.setResponseType('text');
      fragment_loader.load(fragment_url, (fragment_text) => {
        onLoad(vertex_text, fragment_text);
      });
    },
    onProgress,
    onError
  );
}
