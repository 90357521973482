import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import MarqueeText from 'vue-marquee-text-component';

import { loadScript } from '@/assets/js/utils';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/eventBus';
import '@/assets/js/mq';
import '@/assets/js/raf';
import '@/assets/js/layout';
// import wb from './registerServiceWorker';

Vue.config.productionTip = false;

sync(store, router);

// Vue.prototype.$workbox = wb;
Vue.use(InlineSvgPlugin);

Vue.component('MarqueeText', MarqueeText);

// eslint-disable-next-line no-undef
const { url: baseUrl } = __VUE_WORDPRESS__.routing;
Vue.prototype.$relativeUrl = (url) => (url
  ? process.env.NODE_ENV === 'development' && process.env.VUE_APP_PROXY_TARGET
    ? url
      .replace(process.env.VUE_APP_PROXY_TARGET, '')
      .replace(baseUrl, '')
      .replace(process.env.VUE_APP_BASE_URL, '/')
    : url.replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
  : false);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

router.onReady(() => app.$mount('#app'));

// eslint-disable-next-line
window._iub = [];
// eslint-disable-next-line
window._iub.csConfiguration = {
  lang: 'it',
  siteId: 940041,
  cookiePolicyId: 8252303,
  consentOnScroll: false,
  consentOnContinuedBrowsing: false,
  // invalidateConsentWithoutLog: true,
  banner: {
    applyStyles: false,
    position: 'bottom',
    slideDown: 'true',
    content:
      'Utilizziamo cookie e tecnologie simili come specificato nella %{cookie_policy_link}. Interagendo con questa pagina acconsenti all\'utilizzo di tali tecnologie.',
    cookiePolicyLinkCaption: 'Cookie Policy',
    acceptButtonDisplay: true,
    rejectButtonDisplay: true,
    explicitWithdrawal: true,
    closeButtonDisplay: false,
  },
  callback: {
    onConsentGiven: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
      }
    },
    onConsentRejected: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });
      }
    },
    onBannerClosed: () => {
      Vue.prototype.$bus.$emit('iubendaClose');
    },
    onBannerShown: () => {
      Vue.prototype.$bus.$emit('iubendaReady');
    },
  },
  preferenceCookie: {
    expireAfter: 180,
  },
};

loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js');

document.body.addEventListener('click', (e) => {
  if (e.target.classList.contains('check-cookie-consent')) {
    e.preventDefault();
    // eslint-disable-next-line
    window._iub.cs.api.openPreferences();
  }
});
