<template>
  <footer
    v-if="footer"
    class="page-footer typo--s-alt"
  >
    <Spacer all="xs">
      <Spacer
        v-if="$mq.isMobile"
        bottom="xs"
      >
        <div class="separator--row" />
      </Spacer>
      <Grid>
        <Column
          v-if="$mq.isMobile"
          :size="{ default: 2, m: 3 }"
        >
          <Flex
            gap
            row-gap="xs"
            align="start"
            dir="column"
          >
            <Spacer x="xs">
              <inline-svg
                class="logo"
                :src="require(`@/assets/svg/logo.svg`)"
              />
            </Spacer>
          </Flex>
        </Column>
        <Column :size="{ default: 10, m: 12 }">
          <Grid
            row-gap="l"
            col="12"
          >
            <Grid
              :col="{ default: 12, m: 3 }"
              row-gap="l"
            >
              <Flex
                v-if="!$mq.isMobile"
                gap
                row-gap="xs"
                align="start"
                dir="column"
              >
                <div class="separator--row" />
                <inline-svg
                  class="logo"
                  :src="require(`@/assets/svg/logo.svg`)"
                />
              </Flex>
              <Flex
                v-for="(info, index) in footer.information"
                :key="index"
                gap
                row-gap="xs"
                align="start"
                dir="column"
              >
                <div
                  v-if="!$mq.isMobile"
                  class="separator--row"
                />
                <div class="col-body">
                  <template v-if="info.type === 'text'">
                    <div v-html="info.text" />
                  </template>
                  <template v-else-if="info.type === 'menu'">
                    <Flex
                      gap
                      row-gap="xs"
                      tag="ul"
                      dir="column"
                    >
                      <li
                        v-for="item in menu(info.menu).items"
                        :key="item.id"
                        :class="[...item.css]"
                      >
                        <Cta
                          :data="{
                            url: item.url,
                            title: item.content,
                            target: item.target,
                          }"
                        />
                      </li>
                    </Flex>
                  </template>
                  <template v-if="info.type === 'custom' && info.id === 'last'">
                    <Flex
                      gap
                      row-gap="xs"
                      tag="ul"
                      dir="column"
                    >
                      <li
                        v-for="item in menu(info.menu).items"
                        :key="item.id"
                        :class="[...item.css]"
                      >
                        <Cta
                          :data="{
                            url: item.url,
                            title: item.content,
                            target: item.target,
                          }"
                        />
                      </li>
                      <li v-html="info.text" />
                      <li>
                        <Newsletter />
                      </li>
                    </Flex>
                  </template>
                </div>
              </Flex>
            </Grid>

            <Grid
              class="impressum"
              row-gap="l"
              :col="{ default: 12, m: 3 }"
            >
              <div class="u-hidden--until-m" />
              <div class="u-hidden--until-m" />

              <Flex
                gap
                row-gap="xs"
                :column-gap="$mq.isMobile ? '0' : 'l'"
                tag="ul"
              >
                <Column
                  v-for="item in menu('social').items"
                  :key="item.id"
                  :class="[...item.css]"
                  :size="{ default: 12, l: 'auto' }"
                >
                  <Cta
                    :data="{
                      url: item.url,
                      title: item.content,
                      target: item.target,
                    }"
                  />
                </Column>
              </Flex>

              <div>
                Website by
                <a
                  href="https://www.gigadesignstudio.com"
                  target="_blank"
                >Giga</a>
              </div>
            </Grid>
          </Grid>
        </Column>
      </Grid>
    </Spacer>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import Newsletter from '@/components/ui/newsletter';
import Cta from '@/components/typo/cta';

export default {
  name: 'Footer',
  components: {
    Cta,
    Newsletter,
  },
  computed: {
    ...mapGetters(['options', 'currentPost', 'menu']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);

  .logo {
    width: 30px;

    @include mq(m) {
      width: 35px;
    }
  }

  .col-body {
    width: 100%;
  }

  .impressum {
  }

  ::v-deep {
    a,
    button {
      display: inline-block;

      @include mq($and: $hover) {
        transition: color 0.2s $ease-custom;
        &:hover {
          color: var(--grey-d);
        }
      }
    }
  }
}
</style>
