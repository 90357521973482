<template>
  <transition
    name="embed"
    appear
  >
    <Flex
      v-if="embed"
      class="embed"
      justify="space-between"
    >
      <transition name="fade">
        <div class="embed-wrapper">
          <Flex
            v-if="embed.title"
            align="center"
            class="embed-title"
          >
            <router-link :to="$relativeUrl(embed.link)">
              <MarqueeText
                :repeat="repeat"
                :duration="10"
              >
                <Spacer x="xs">
                  <Title :data="{ value: embed.title, size: 's' }" />
                </Spacer>
              </MarqueeText>
            </router-link>
          </Flex>

          <template v-if="embed.provider === 'mixcloud-iframe'">
            <div
              :key="embed.id"
              class="mixcloud"
              :data-id="embed.id"
              v-html="embed.html"
            />
          </template>

          <template v-if="embed.provider === 'spreaker-iframe'">
            <div
              :key="embed.id"
              class="spreaker"
            >
              <a
                :id="embed.html"
                class="spreaker-player"
                href="https://www.spreaker.com"
                :data-resource="`episode_id=${embed.html}`"
                data-autoplay="true"
                data-episode-image-position="left"
                data-hide-logo="true"
                data-hide-likes="true"
                data-hide-comments="true"
                data-hide-episode-description="true"
                data-hide-sharing="true"
                data-hide-download="true"
                data-color="000000"
                data-width="100%"
                data-height="200px"
              >Listen to my podcast</a>
            </div>
          </template>

          <template v-if="embed.provider === 'generic-iframe'">
            <div
              :key="embed.id"
              class="mixcloud"
              :data-id="embed.id"
              v-html="embed.html"
            />
          </template>
        </div>
      </transition>
      <div class="embed-close">
        <Icon
          name="close"
          :fn="closeEmbed"
        />
      </div>
    </Flex>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadScript } from '@/assets/js/utils';
import Title from '@/components/typo/title';
import Icon from '@/components/ui/icon';

export default {
  name: 'Embed',
  components: {
    Icon,
    Title,
  },
  data() {
    return {
      widget: {},
      widgetIsReady: false,
      embedStatus: false,
    };
  },
  computed: {
    ...mapGetters(['embed', 'audio']),
    repeat() {
      return this.embed && this.embed.title
        ? this.embed.title.length < 30
          ? 20
          : 10
        : false;
    },
  },
  watch: {
    embed: {
      deep: true,
      handler(val, old) {
        if (old) {
          if (val.id !== old.id) {
            if (this.widget[old.id]) {
              if (old.provider === 'mixcloud-iframe') {
                this.widget[old.id].events.pause.off(this.setEmbedPause);
                this.widget[old.id].events.play.off(this.setEmbedPlay);
              }
              this.$bus.$emit('DELETE_EMBED', old.id);
              delete this.widget[old.id];
            }
          }
        }
        if (val) {
          this.$store.commit('TOGGLE_LIVE', false);

          if (val && !this.widget[val.id]) {
            this.$nextTick(() => {
              if (val.provider === 'mixcloud-iframe') {
                this.setSize(90);
                this.initMixcloudWidget();
              } else if (val.provider === 'spreaker-iframe') {
                this.initSpreakerWidget();
                this.setSize(230);
              } else {
                this.setSize();
              }
              this.$bus.$on('windowResized', this.setSize);
            });
          }
        }
      },
    },
  },
  mounted() {
    this.$bus.$on('TOGGLE_PLAY', this.togglePlay);
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.setSize);
  },
  methods: {
    setSize(force) {
      let height = this.$el.clientHeight + 30;

      if (force) {
        height = force;
      }

      document.documentElement.style.setProperty('--embed', `${height}px`);
    },
    closeEmbed() {
      this.setSize(0);
      this.$bus.$off('windowResized', this.setSize);
      this.$store.commit('SET_EMBED', false);
    },
    initMixcloudWidget() {
      const iframe = this.$el.querySelector(
        `[data-id="${this.embed.id}"] iframe`,
      );
      this.widget[this.embed.id] = window.Mixcloud.PlayerWidget(iframe);
      this.widget[this.embed.id].ready.then(() => {
        if (!this.widget[this.embed.id].getIsPaused()) {
          this.embedStatus = true;
          this.$store.commit('SET_EMBED_STATUS', this.embedStatus);
          if (this.audio) {
            this.$store.commit('SET_AUDIO', false);
          }
        }
        this.widgetIsReady = true;

        this.widget[this.embed.id].events.pause.on(this.setEmbedPause);
        this.widget[this.embed.id].events.play.on(this.setEmbedPlay);
      });
    },
    setEmbedPause() {
      this.embedStatus = false;
      this.$store.commit('SET_EMBED_STATUS', this.embedStatus);
    },
    setEmbedPlay() {
      if (this.audio) {
        this.$store.commit('SET_AUDIO', false);
      }
      this.embedStatus = true;
      this.$store.commit('SET_EMBED_STATUS', this.embedStatus);
    },
    togglePlay(id) {
      if (id === this.embed.id) {
        if (
          this.embed.provider === 'mixcloud-iframe'
          && this.widget[this.embed.id]
        ) {
          this.widget[this.embed.id].togglePlay();
        }
      }
    },

    initSpreakerWidget() {
      loadScript('https://widget.spreaker.com/widgets.js').then(() => {
        this.widget[this.embed.id] = window.SP.getWidget(this.embed.html);

        this.setEmbedPlay();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.embed-enter-active,
.embed-leave-active {
  transition: transform 0.4s $ease-custom;
}

.embed-enter,
.embed-leave-to {
  transform: translateY(100%) translateY(var(--bar-height));
}

.embed {
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  width: 100%;
  line-height: 0;
  max-width: 620px;
  z-index: 150;
  background: var(--white);

  &-title {
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: var(--bar-height);
    background: var(--grey-xxd);
    color: var(--white);

    a {
      display: block;
      width: 100%;
    }
  }

  &-close {
    flex: 0 0 60px;
  }

  &-wrapper {
    flex: 1 0 0;
  }

  .mixcloud {
    position: relative;
    max-width: 560px;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 60px;
      width: calc(100% - 60px);
      height: 1px;
      background: var(--white);
      z-index: 1;
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: var(--white);
      z-index: 1;
      pointer-events: none;
    }
  }
}
</style>
