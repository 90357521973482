<template>
  <main>
    <Spacer
      v-if="theme !== 'about'"
      :top="
        liveStatus !== 'video'
          ? checkArchiveSection
            ? { default: 'xs', s: '0' }
            : { default: 'xs', s: 'xxl' }
          : 's'
      "
    />
    <component
      :is="components.get(block.acf_fc_layout)"
      v-for="(block, index) in post.acf.section"
      :key="index"
      :class="[
        theme !== 'about'
          ? block.theme && (block.theme === 'hero' || block.theme === 'banner')
            ? 'spacer--padding--b--xxl'
            : 'spacer--padding--b--l'
          : 'spacer--padding--t--s spacer--padding--t-s--xxl',
      ]"
      :data="block"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import components from '@/assets/js/components';
import data from '@/mixins/data';

export default {
  name: 'Page',
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  computed: {
    ...mapGetters(['liveStatus', 'theme']),
    checkArchiveSection() {
      return (
        this.post.acf
        && this.post.acf.section
        && this.post.acf.section[0]
        && this.post.acf.section[0].layout === 'thumb_archive'
      );
    },
  },
};
</script>

<style lang="scss">
[data-theme="about"] {
  main {
    position: relative;
    min-height: 700px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding-bottom: var(--spacer-xs);

    @include mq(s) {
      min-height: calc(var(--vh) * 100 - 20px);
    }

    .label {
      text-transform: uppercase;
    }
  }
}
</style>
