var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.footer)?_c('footer',{staticClass:"page-footer typo--s-alt"},[_c('Spacer',{attrs:{"all":"xs"}},[(_vm.$mq.isMobile)?_c('Spacer',{attrs:{"bottom":"xs"}},[_c('div',{staticClass:"separator--row"})]):_vm._e(),_c('Grid',[(_vm.$mq.isMobile)?_c('Column',{attrs:{"size":{ default: 2, m: 3 }}},[_c('Flex',{attrs:{"gap":"","row-gap":"xs","align":"start","dir":"column"}},[_c('Spacer',{attrs:{"x":"xs"}},[_c('inline-svg',{staticClass:"logo",attrs:{"src":require(`@/assets/svg/logo.svg`)}})],1)],1)],1):_vm._e(),_c('Column',{attrs:{"size":{ default: 10, m: 12 }}},[_c('Grid',{attrs:{"row-gap":"l","col":"12"}},[_c('Grid',{attrs:{"col":{ default: 12, m: 3 },"row-gap":"l"}},[(!_vm.$mq.isMobile)?_c('Flex',{attrs:{"gap":"","row-gap":"xs","align":"start","dir":"column"}},[_c('div',{staticClass:"separator--row"}),_c('inline-svg',{staticClass:"logo",attrs:{"src":require(`@/assets/svg/logo.svg`)}})],1):_vm._e(),_vm._l((_vm.footer.information),function(info,index){return _c('Flex',{key:index,attrs:{"gap":"","row-gap":"xs","align":"start","dir":"column"}},[(!_vm.$mq.isMobile)?_c('div',{staticClass:"separator--row"}):_vm._e(),_c('div',{staticClass:"col-body"},[(info.type === 'text')?[_c('div',{domProps:{"innerHTML":_vm._s(info.text)}})]:(info.type === 'menu')?[_c('Flex',{attrs:{"gap":"","row-gap":"xs","tag":"ul","dir":"column"}},_vm._l((_vm.menu(info.menu).items),function(item){return _c('li',{key:item.id,class:[...item.css]},[_c('Cta',{attrs:{"data":{
                          url: item.url,
                          title: item.content,
                          target: item.target,
                        }}})],1)}),0)]:_vm._e(),(info.type === 'custom' && info.id === 'last')?[_c('Flex',{attrs:{"gap":"","row-gap":"xs","tag":"ul","dir":"column"}},[_vm._l((_vm.menu(info.menu).items),function(item){return _c('li',{key:item.id,class:[...item.css]},[_c('Cta',{attrs:{"data":{
                          url: item.url,
                          title: item.content,
                          target: item.target,
                        }}})],1)}),_c('li',{domProps:{"innerHTML":_vm._s(info.text)}}),_c('li',[_c('Newsletter')],1)],2)]:_vm._e()],2)])})],2),_c('Grid',{staticClass:"impressum",attrs:{"row-gap":"l","col":{ default: 12, m: 3 }}},[_c('div',{staticClass:"u-hidden--until-m"}),_c('div',{staticClass:"u-hidden--until-m"}),_c('Flex',{attrs:{"gap":"","row-gap":"xs","column-gap":_vm.$mq.isMobile ? '0' : 'l',"tag":"ul"}},_vm._l((_vm.menu('social').items),function(item){return _c('Column',{key:item.id,class:[...item.css],attrs:{"size":{ default: 12, l: 'auto' }}},[_c('Cta',{attrs:{"data":{
                    url: item.url,
                    title: item.content,
                    target: item.target,
                  }}})],1)}),1),_c('div',[_vm._v(" Website by "),_c('a',{attrs:{"href":"https://www.gigadesignstudio.com","target":"_blank"}},[_vm._v("Giga")])])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }