var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumb"},[_c('div',{class:['thumb-player']},[(_vm.featuredImage)?_c('Figure',{attrs:{"data":{ ..._vm.featuredImage, ratio: 0.5625, align: _vm.alignImage },"disable-caption":""}}):_vm._e(),_c('Cta',{staticClass:"mute",attrs:{"data":{
        title: !_vm.isMuted ? 'Muta' : 'Audio',
        fn: _vm.toggleMuted,
      },"theme":"button"}})],1),_c('div',{staticClass:"thumb-content"},[_c('Title',{staticClass:"t-ellipsis",attrs:{"data":{ value: _vm.title }}}),_c('Artist',{attrs:{"data":_vm.data.episode}}),(_vm.content)?_c('RichText',{staticClass:"content content-clamp",attrs:{"data":{ value: _vm.clearContent(_vm.content.rendered) }}}):_vm._e(),(_vm.show)?_c('div',{staticClass:"show-link"},[_c('Cta',{attrs:{"data":{
          title: 'Scopri di più',
          url: _vm.show.link,
        },"theme":"rounded-full"}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }