<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :class="[
      'icon',
      `icon-theme--${theme}`,
      `icon--size-${size}`,
      `icon-${name}`,
    ]"
    :to="$relativeUrl(to)"
    @click.stop="fn"
  >
    <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'l',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: String,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.icon {
  position: relative;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--size-onair {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      left: 0;
      transform: translate3d(0, -50%, 0);
    }
  }

  &--size-l {
    width: 60px;
    height: 60px;
  }

  &--size-podcast {
    width: 100%;

    svg {
      width: 60%;
      height: auto;
      transform: none;
      top: 0;
      left: 0;
    }
  }

  &-theme {
    &--default {
      // background: var(--primary-color);
      color: var(--black);
    }

    &--warning {
      path {
        fill: var(--warning-color);
      }
    }
    &--invert {
      path {
        fill: var(--white);
      }
    }

    &--circle {
      background: var(--black);
      border-radius: 50%;
      path {
        fill: var(--white);
      }
    }
  }

  &--close {
    position: absolute;
    top: $unit * 0.5;
    right: $unit * 0.5;
    z-index: 10;
  }
}
</style>
