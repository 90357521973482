<template>
  <div class="thumb">
    <div :class="['thumb-player']">
      <Figure
        v-if="featuredImage"
        :data="{ ...featuredImage, ratio: 0.5625, align: alignImage }"
        disable-caption
      />

      <Cta
        :data="{
          title: !isMuted ? 'Muta' : 'Audio',
          fn: toggleMuted,
        }"
        theme="button"
        class="mute"
      />
    </div>

    <div class="thumb-content">
      <Title
        class="t-ellipsis"
        :data="{ value: title }"
      />
      <Artist :data="data.episode" />

      <RichText
        v-if="content"
        class="content content-clamp"
        :data="{ value: clearContent(content.rendered) }"
      />

      <div
        v-if="show"
        class="show-link"
      >
        <Cta
          :data="{
            title: 'Scopri di più',
            url: show.link,
          }"
          theme="rounded-full"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clearContent } from '@/assets/js/utils';

import Figure from '@/components/media/figure';
import Artist from '@/components/thumb/components/artist';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import RichText from '@/components/typo/richtext';

export default {
  name: 'ThumbOnAir',
  components: {
    Figure,
    RichText,
    Cta,
    Title,
    Artist,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      clearContent,
    };
  },
  computed: {
    ...mapGetters(['audio', 'isMuted']),
    title() {
      const title = this.data.titolo;
      return title;
    },
    featuredImage() {
      const placeholder = {
        height: 450,
        mime_type: 'image/jpeg',
        subtype: 'jpeg',
        type: 'image',
        url: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        sizes: {
          xxl: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        },
        width: 800,
      };
      return this.data.episode.gds_featured_image
        ? this.data.episode.gds_featured_image
        : this.data.episode.acf
          && this.data.episode.acf.show
          && this.data.episode.acf.show.gds_featured_image
          ? this.data.episode.acf.show.gds_featured_image
          : this.data.episode.acf
          && this.data.episode.acf.show
          && this.data.episode.acf.show.acf.resident_guest_programma
          && this.data.episode.acf.show.acf.resident_guest_programma[0]
          && this.data.episode.acf.show.acf.resident_guest_programma[0]
            .gds_featured_image
            ? this.data.episode.acf.show.acf.resident_guest_programma[0]
              .gds_featured_image
            : placeholder;
    },
    alignImage() {
      return this.data.episode.gds_featured_image
        ? this.data.episode.acf.align_cover
        : this.data.episode.acf
          && this.data.episode.acf.show
          && this.data.episode.acf.show.gds_featured_image
          ? this.data.episode.acf.align_cover
          : this.data.episode.acf
          && this.data.episode.acf.show
          && this.data.episode.acf.show.acf.resident_guest_programma
          && this.data.episode.acf.show.acf.resident_guest_programma[0]
          && this.data.episode.acf.show.acf.resident_guest_programma[0]
            .gds_featured_image
            ? this.data.episode.acf.align_cover
            : 'center';
    },
    show() {
      if (this.data.episode.type === 'show') {
        return this.data.episode;
      }
      if (this.data.episode.acf.show) {
        return this.data.episode.acf.show;
      }
      return false;
    },
    content() {
      return this.data.episode.content;
    },
  },
  methods: {
    toggleRadio() {
      this.$store.commit('SET_AUDIO', !this.audio);
    },
    toggleMuted() {
      this.$store.commit('TOGGLE_MUTED');
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  .tags {
  }

  .content {
    margin-top: var(--spacer-xs);
  }

  .show-link {
    margin-top: var(--spacer-m);
    --bg: var(--grey);
  }

  &-content {
    margin-top: var(--spacer-xxs);
    position: relative;
  }

  .mute {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--white);
    mix-blend-mode: difference;
  }

  .thumb-player {
    position: relative;
    margin-top: -30px;
    cursor: pointer;
    left: -10px;
    width: calc(100% + 20px);

    @include mq(s) {
      margin-top: -25px;
    }

    .icon {
      opacity: 1;
    }
  }
}
</style>
