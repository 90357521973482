var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.onAirKey,class:[
    'on-air',
    _vm.overlayStatus('on-air') ? 'on-air--open' : false,
  ]},[_c('Flex',{class:[
      'on-air-header',
      'bar',
      _vm.now && _vm.now.episode ? 'on-air-with-now' : false,
      _vm.now && _vm.now.episode && _vm.now.episode.gds_featured_image
        ? 'on-air-with-img'
        : false,
    ],attrs:{"align":"center","justify":"space-between"}},[_c('Cta',{staticClass:"on-air-cta on-air-cta-live",attrs:{"data":{
        title: _vm.audio ? 'Pausa' : 'Play',
        fn: _vm.toggleRadio,
        toggle: 'circle',
      },"theme":"button"}},[_c('template',{slot:"circle"},[_c('span',{staticClass:"on-air-circle"})])],2),(_vm.live.video)?_c('Toggle',{staticClass:"on-air-cta on-air-cta-toggle",attrs:{"off":"v","on":"a","callback":_vm.toggleLive,"watch-from-props":_vm.liveStatus !== 'video',"initial-state":_vm.liveStatus !== 'video'}}):_vm._e(),_c('Flex',{class:[
        'on-air-title',
        _vm.live.video ? 'on-air-title-toggle' : false,
        _vm.now || (_vm.next && _vm.next.length > 0)
          ? 'on-air-title-cta'
          : false,
        _vm.audio ? 'on-air-title-playing' : false,
      ],attrs:{"align":"center"}},[_c('div',{on:{"click":_vm.toggleOnAir}},[_c('MarqueeText',{key:_vm.nowTitle,attrs:{"repeat":10,"duration":10}},[_c('Spacer',{attrs:{"x":"xs"}},[_c('Title',{attrs:{"data":{ value: _vm.nowTitle, size: 's' }}})],1)],1)],1)])],1),(_vm.now && _vm.now.episode && _vm.now.episode.gds_featured_image)?_c('Figure',{staticClass:"thumb-hover",attrs:{"data":{
      ..._vm.now.episode.gds_featured_image,
      ratio: 0.5625,
      align: 'center',
    },"disable-caption":""}}):_vm._e(),_c('transition',{attrs:{"css":false},on:{"enter":_vm.enterOverlay,"leave":_vm.leaveOverlay}},[(_vm.overlayStatus('on-air'))?_c('Flex',{staticClass:"wrapper"},[_c('Flex',{staticClass:"wrapper-clip"},[_c('Spacer',{staticClass:"wrapper-padded"},[_c('Grid',{staticClass:"on-air-main",attrs:{"grid":"2","row-gap":"xs"}},[_c('Column',{attrs:{"size":"2"}},[(_vm.now)?_c('ThumbOnAir',{attrs:{"data":_vm.now}}):_vm._e(),_c('Spacer',{attrs:{"bottom":"s"}})],1),_vm._l((_vm.next),function(set){return _c('Column',{key:set.id,attrs:{"size":"1"}},[_c('ThumbNext',{attrs:{"data":set}})],1)}),_c('Column',{attrs:{"size":"2"}},[_c('Cta',{style:({
                  '--bg': 'var(--black)',
                  '--border': 'var(--black)',
                  '--color': 'var(--white)',
                  '--bg-hover': 'var(--black)',
                  '--color-hover': 'var(--white)',
                }),attrs:{"data":{
                  title: 'Schedule',
                  url: '/schedule',
                },"theme":"rounded-full"}})],1)],2),_c('Flex',{staticClass:"on-air-close",attrs:{"justify":"center","align":"center"}},[_c('Cta',{attrs:{"data":{
                title: 'Chiudi',
                fn: _vm.toggleOnAir,
              },"theme":"button"}})],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }