<template>
  <div>
    <!--  -->
  </div>
</template>

<script>
export default {
  name: 'Asteroid',
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      asteroid: null,
      isInit: false,
    };
  },
  mounted() {
    /* eslint-disable */
    const Asteroid = require("@/assets/js/asteroid.js");

    const rand_max = 2;
    const rand_min = 1;

    const rand = Math.round(Math.random() * (rand_max - rand_min) + rand_min);
    this.asteroid = new Asteroid(this.$el, this.data, rand);
    this.asteroid.load();

    this.$bus.$on("ASTEROID_READY", this.init);
    this.$bus.$emit("initApp");
  },
  beforeDestroy() {
    this.$bus.$off("ASTEROID_READY", this.init);
    this.$el.parentNode.removeEventListener(
      "mousemove",
      this.asteroid.handleMouseMove.bind(this.asteroid)
    );
    this.asteroid.destroy();
    this.$aion.remove("asteroid");
    this.asteroid = null;
  },
  methods: {
    init() {
      if (this.asteroid.loadingCompleted && !this.isInit) {
        this.isInit = true;
        this.asteroid.init();
        this.$el.parentNode.addEventListener(
          "mousemove",
          this.asteroid.handleMouseMove.bind(this.asteroid)
        );
        this.$aion.add(this.asteroid.animate.bind(this.asteroid), "asteroid");
      }
    },
  },
};
</script>

<style></style>
