<template>
  <Flex tag="nav">
    <div class="wrapper-padded">
      <Grid
        row-gap="m"
        col="12"
        class="nav-menu"
        align="start"
      >
        <div
          v-for="item in navigation"
          :key="item.id"
          :class="[...item.css]"
          row-gap="0"
          column-gap="0"
          align="start"
        >
          <Grid
            v-if="item.children"
            col="12"
            row-gap="0"
          >
            <Cta
              v-for="child in item.children"
              :key="child.id"
              :data="{
                url: child.url,
                title: child.content,
                target: child.target,
                size: 'm',
              }"
            />
          </Grid>
        </div>
      </Grid>

      <Flex
        class="logo u-hidden--from-s"
        align="center"
        justify="center"
      >
        <inline-svg :src="require(`@/assets/svg/logo.svg`)" />
      </Flex>
    </div>
  </Flex>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Menu',
  components: {
    Cta,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: var(--white);

  .wrapper-padded {
    padding-top: var(--spacer-m);
    flex: 0 0 100%;
    width: 100%;

    @include mq($until: s) {
      display: grid;
      align-items: flex-start;
    }
  }

  .logo {
    flex: 1 0 0;

    svg {
      width: 46px;
    }
  }
}
</style>
