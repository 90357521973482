import { render, staticRenderFns } from "./overlay.vue?vue&type=template&id=71d35c44&scoped=true&"
import script from "./overlay.vue?vue&type=script&lang=js&"
export * from "./overlay.vue?vue&type=script&lang=js&"
import style0 from "./overlay.vue?vue&type=style&index=0&id=71d35c44&prod&lang=scss&scoped=true&"
import style1 from "./overlay.vue?vue&type=style&index=1&id=71d35c44&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d35c44",
  null
  
)

export default component.exports