import { parse, stringify } from '@billjs/query-string';

export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;
  /* eslint-disable-next-line no-mixed-operators, no-undef */
  if (
    'ontouchstart' in window
    || (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true;
  }
  const query = [
    '(',
    prefixes.join('touch-enabled),('),
    'heartz',
    ')',
  ].join('');
  return mq(query);
};

export const flatAndSort = (object, key) => {
  const keys = Object.keys(object);
  const sortedKeys = keys.sort(
    (a, b) => object[a][key] - object[b][key],
  );
  const sortedObject = [];
  sortedKeys.forEach((k) => sortedObject.push({ key: k, ...object[k] }));
  return sortedObject;
};

export const getBreakpoint = () => JSON.parse(
  window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--breakpoints'),
);

export const is = (breakpoint) => {
  const b = window.innerWidth;
  const c = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--breakpoints-${breakpoint}`),
    10,
  );
  if (c) {
    return b < c;
  }
  return false;
};

export const isSafari = () => typeof navigator !== 'undefined'
  && navigator.vendor
  && navigator.vendor.includes('Apple')
  && navigator.userAgent
  && !navigator.userAgent.includes('CriOS')
  && !navigator.userAgent.includes('Chrome')
  && !navigator.userAgent.includes('FxiOS');

export const isIOS = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform)
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isEdge = () => typeof window !== 'undefined'
  && window.navigator.userAgent.includes('Edge');

export const isIE = () => typeof window !== 'undefined'
  && (window.navigator.userAgent.includes('MSIE')
    || window.navigator.userAgent.includes('Trident/'));

export const norm = (val, max, min) => (val - min) / (max - min);

export const viewport = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  ratio: window.innerWidth / window.innerHeight,
});

export const lerp = (a, b, n) => (1 - n) * a + n * b;

export const modulate = (value, rangeA, rangeB, limit) => {
  if (limit == null) {
    limit = false;
  } // eslint-disable-line
  const [fromLow, fromHigh] = Array.from(rangeA);
  const [toLow, toHigh] = Array.from(rangeB);
  const result = toLow
    + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow);

  if (limit === true) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow;
      }
      if (result > toHigh) {
        return toHigh;
      }
    } else {
      if (result > toLow) {
        return toLow;
      }
      if (result < toHigh) {
        return toHigh;
      }
    }
  }

  return result;
};

export const loadScript = (src) => new Promise((resolve) => {
  const s = document.createElement('script');
  let r = false;
  s.type = 'text/javascript';
  s.src = src;
  // eslint-disable-next-line
    s.onload = s.onreadystatechange = function () {
    if (
      !r
        && (!this.readyState || this.readyState === 'complete')
    ) {
      r = true;
      resolve();
    }
  };
  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
});

export const getParm = (url, base) => {
  const re = new RegExp(`(\\?|&)${base}\\=([^&]*)(&|$)`);
  const matches = url.match(re);
  if (matches) {
    return matches[2];
  }
  return '';
};

export const parseVideoURL = (url) => {
  const retVal = {};
  const matches = url.match(/vimeo.com\/video\/(\d+)/);

  if (url.indexOf('youtube.com/') !== -1) {
    retVal.provider = 'youtube';
    if (url.indexOf('/embed/') !== -1) {
      const id = url.split('/embed/')[1].split('?')[0];
      retVal.id = id;
    } else {
      retVal.id = getParm(url, 'v');
    }
  } else if (matches) {
    retVal.provider = 'vimeo';
    // eslint-disable-next-line prefer-destructuring
    retVal.id = matches[1];
  }

  return retVal;
};

export const setContent = (item) => {
  const {
    label,
    tag_label,
    size_label,
    title,
    tag_title,
    size_title,
    rich_text,
    ctas,
    wrapper,
    layout,
    cta_layout,
  } = item;

  return {
    label,
    tag_label,
    size_label,
    title,
    tag_title,
    size_title,
    rich_text,
    ctas,
    wrapper,
    layout,
    cta_layout,
  };
};

export const setMedia = (item) => {
  const { media } = item;

  return media;
};

export const checkContent = (item) => {
  const {
    label, title, rich_text, ctas,
  } = item;
  return label || title || rich_text || ctas;
};

export const checkMedia = (item) => {
  const { media } = item;

  let check = true;

  if (media.type === 'image') {
    check = media.image.default;
  }

  return media && media[media.type] && check;
};

export const fixMixcloud = (iframe) => {
  const srcWithQuotes = iframe.match(/src=([^\s]*)\s/)[1];
  const src = srcWithQuotes.substring(1, srcWithQuotes.length - 1);
  const srcQs = src.split('/?')[1];
  const qs = parse(src);
  const newSrc = stringify({
    light: 1,
    mini: 1,
    autoplay: 1,
    feed: qs.feed,
    hide_cover: 1,
    hide_artwork: window.innerWidth < 768 ? 1 : undefined,
  });

  const newIframeSrc = iframe.replace(srcQs, newSrc);
  if (newIframeSrc.includes('allow="autoplay"')) {
    return newIframeSrc;
  }
  return newIframeSrc.replace(' >', ' allow="autoplay">');
};

export const fixSpreaker = (iframe) => {
  const srcWithQuotes = iframe.match(/src=([^\s]*)\s/)[1];
  const src = srcWithQuotes.substring(1, srcWithQuotes.length - 1);
  // const srcQs = src.split('?')[0];

  const qs = parse(src);
  // const newSrc = stringify({
  //   light: 1,
  //   playlist: 'false',
  //   mini: 1,
  //   autoplay: 1,
  //   episode_id: qs.episode_id,
  //   hide_cover: 1,
  //   color: '#000000',
  //   episode_image_position: 'left',
  //   'hide-logo': 'true',
  // });

  // return `${srcQs}?${newSrc}`;

  return qs.episode_id;

  // const newIframeSrc = iframe.replace(srcQs, newSrc);
  // if (newIframeSrc.includes('allow="autoplay"')) {
  //   return newIframeSrc;
  // }
  // return newIframeSrc.replace('>', ' allow="autoplay" class="spreaker-player" id="spreaker-iframe">');
};

export const fnSchedule = (schedule) => {
  const next = [];
  let setIndex;

  const time = new Date(
    new Date().toLocaleString('en', {
      timeZone: 'Europe/Rome',
    }),
  );
  const hour = time.getHours() * 100;
  const minutes = time.getMinutes();
  const delta = hour + minutes;
  const now = schedule.find((set, index) => {
    const start = parseInt(set.ora_inizio.replace(':', ''), 10);
    const end = parseInt(set.ora_fine.replace(':', ''), 10);

    if (start <= delta && delta < end) {
      setIndex = index;
      return true;
    }

    if (start > delta) {
      if (!setIndex) {
        setIndex = index - 1;
      }
      return false;
    }
    return false;
  });

  if (schedule[setIndex + 1]) next.push(schedule[setIndex + 1]);
  if (schedule[setIndex + 2]) next.push(schedule[setIndex + 2]);

  return {
    now: now
      ? {
        ...now,
        currentIndex: setIndex,
      }
      : false,
    next,
  };
};

export const clearContent = (content) => {
  if (content) {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.firstChild.innerHTML;
  }
  return '';
};
