var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.embed ? 'is-embed-active' : false],attrs:{"id":"app"}},[_c('AppOverlay',{attrs:{"from-path":_vm.filteredPath}}),(_vm.liveStatus === 'video')?_c('Live',{attrs:{"data":{
      src: _vm.live.video,
      params: {
        enableInfoAndActivity: 'false',
        defaultDrawer: 'feed',
        autoPlay: 'true',
      },
    }}}):_vm._e(),_c('transition',{attrs:{"css":false,"appear":"","mode":"out-in"},on:{"enter":_vm.enter,"leave":_vm.leave}},[_c('router-view',{key:_vm.filteredPath.path})],1),_c('AppFooter'),_c('AppEmbed'),(
      _vm.options &&
        ((_vm.options.newsletter && _vm.options.newsletter.active) ||
          (_vm.options.pwa && _vm.options.pwa.active) ||
          (_vm.options.custom && _vm.options.custom.length > 0))
    )?_c('Notifications'):_vm._e(),_c('Modal'),_c('Snackbar'),_c('AudioPlayer',{attrs:{"data":{ src: _vm.live.audio }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }