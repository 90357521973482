export default {
  data() {
    return {
      post: null,
    };
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    preview: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.setData(this.$route);
  },
  methods: {
    setData(to) {
      if (this.preview) {
        this.post = this.preview;
        return;
      }
      const request = {
        type: null,
        slug: null,
      };
      request.type = this.type || to.meta.type || 'pages';

      if (this.slug) {
        request.slug = this.slug;
      } else if (to.params.slug) {
        request.slug = to.params.slug;
      } else if (to.meta.slug) {
        request.slug = to.meta.slug;
      }
      this.post = this.$store.state.wp[request.type].default[
        encodeURI(request.slug).toLowerCase()
      ];
      this.$store.commit('SET_CURRENT_POST', this.post);

      if (
        this.post.acf
        && this.post.acf.page_theme
        && this.post.acf.page_theme !== 'default'
      ) {
        document.body.dataset.theme = this.post.acf.page_theme;
      } else if (this.post.type === 'galaxy_express') {
        document.body.dataset.theme = 'light';
      } else {
        document.body.removeAttribute('data-theme');
      }
    },
  },
};
