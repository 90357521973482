<template>
  <transition name="fade">
    <Flex
      v-show="show"
      :class="['modal', `modal-theme--${theme}`, 'overlay']"
      align="center"
      :tabindex="show ? 0 : -1"
    >
      <Spacer
        v-if="data"
        :class="['modal-wrapper']"
        y="xxs"
        x="xs"
      >
        <Flex
          v-if="!data.mandatory"
          justify="end"
        >
          <Cta
            :data="{
              title: 'Chiudi',
              fn: close,
            }"
            class="close"
            theme="button"
          />
        </Flex>

        <template v-if="data.type === 'video'">
          <Video
            ref="video"
            :data="{
              embed: true,
              url: data.url,
            }"
          />
        </template>

        <template v-if="data.type === 'content'">
          <Spacer all="m">
            <Content
              :data="{
                title: data.content.title,
                rich_text: data.content.richtext,
                cta: data.content.cta,
              }"
            />
          </Spacer>
        </template>

        <template v-if="data.type === 'dialog'">
          <Spacer all="m">
            <Grid col="12">
              <Title
                :data="{
                  value: data.content.title,
                }"
              />
              <Richtext
                :data="{
                  value: data.content.richtext,
                }"
              />
              <Flex justify="center">
                <Cta
                  :data="{
                    fn: dialogFn,
                    title: data.content.cta.title,
                  }"
                  theme="button"
                />
              </Flex>
            </Grid>
          </Spacer>
        </template>

        <template v-if="data.type === 'share'">
          <Spacer y="xs">
            <Grid col="3">
              <a
                class="share-link fb"
                :href="
                  `https://facebook.com/dialog/share?display=popup&href=${encodeURIComponent(
                    data.link
                  )}&app_id=2031953930297224`
                "
                target="_blank"
              >
                <inline-svg :src="require(`@/assets/svg/icons/fb.svg`)" />
                <span>Facebook</span>
              </a>
              <a
                class="share-link tw"
                :href="
                  `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    data.link
                  )}&text=${encodeURIComponent(data.title)}&via=RadioraheemMi`
                "
                target="_blank"
              >
                <inline-svg :src="require(`@/assets/svg/icons/tw.svg`)" />
                <span>Twitter</span>
              </a>
              <a
                class="share-link wa"
                :href="
                  `https://api.whatsapp.com/send?text=${encodeURIComponent(
                    data.link
                  )}`
                "
                data-action="share/whatsapp/share"
                target="_blank"
              >
                <inline-svg :src="require(`@/assets/svg/icons/wa.svg`)" />
                <span>Whatsapp</span>
              </a>
              <div
                class="share-link link"
                @click="clipboard"
              >
                <inline-svg :src="require(`@/assets/svg/icons/link.svg`)" />
                <span>{{ copy }}</span>
              </div>
            </Grid>
          </Spacer>
        </template>
      </Spacer>
    </Flex>
  </transition>
</template>

<script>
import Video from '@/components/media/video';
import Content from '@/components/blocks/content';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Modal',
  components: {
    Video,
    Content,
    Cta,
    Title,
    Richtext,
  },
  data() {
    return {
      show: false,
      data: null,
      force: true,
      copy: 'Copia link',
    };
  },
  computed: {
    theme() {
      let theme = 'default';
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('overflow-hidden');
        document.body.addEventListener('keydown', this.close);
      } else {
        document.body.classList.remove('overflow-hidden');
        document.body.removeEventListener('keydown', this.close);
      }
    },
  },
  mounted() {
    this.$bus.$on('modal', this.init);
    this.$bus.$on('openModal', this.open);
    this.$bus.$on('populateModal', this.content);
    this.$bus.$on('removeContentModal', this.remove);
  },
  beforeDestroy() {
    this.$bus.$off('modal', this.init);
    this.$bus.$off('openModal', this.open);
    this.$bus.$off('populateModal', this.content);
    this.$bus.$off('removeContentModal', this.remove);
  },
  methods: {
    init(data) {
      this.data = data;
      this.show = true;
    },
    content(data) {
      this.data = data;
      this.force = false;
    },
    open(id) {
      if (id === this.data.id) {
        this.show = true;
      }
    },
    close(e) {
      if ((e && e.keyCode === 27) || (e && e.type === 'click') || !e) {
        if (this.data.type === 'video' && this.$refs.video) {
          this.$refs.video.plyr.destroy();
        }

        this.show = false;

        if (this.force) this.data = null;
      }
    },
    remove(id) {
      if (id && this.data) {
        if (this.data.id === id) {
          this.show = false;
          this.data = null;
        }
      }
    },
    dialogFn() {
      this.data.fn();
      this.remove(this.data.id);
    },
    clipboard() {
      navigator.clipboard.writeText(this.data.link).then(
        () => {
          this.copy = 'Link copiato';
          if (this.timeout) clearInterval(this.timeout);
          this.timeout = setTimeout(() => {
            this.copy = 'Copia link';
          }, 1200);
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: var(--overlay-black-05);

  @include mq(s) {
    align-items: center;
  }

  &-theme--default {
    .modal-wrapper {
      background: var(--white);
      color: var(--black);
      max-width: 455px;
      width: 100%;
    }
  }
}

.share-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  cursor: pointer;

  svg {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin-bottom: var(--spacer-xxs);
  }

  span {
    @extend %typo--xs;
    display: block;
    flex: 0 0 100%;
    text-align: center;
  }
}
</style>
