<template>
  <main :class="isPost ? 'single--post' : false">
    <Spacer
      v-if="!post.gds_featured_image || post.type === 'partner'"
      :top="liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'"
    />
    <div
      v-if="post.gds_featured_image && post.type !== 'partner'"
      :class="['cover', isProject ? 'cover--project' : false]"
    >
      <Figure
        :data="{
          ...post.gds_featured_image,
          mobile: post.acf.featured_image_mobile,
          align: post.acf.align_cover,
          fit: post.acf.fit_cover,
        }"
        disable-caption
      />
    </div>
    <Spacer all="xs">
      <Spacer
        v-if="breadcrumbs"
        bottom="s"
        class="breadcrumbs"
      >
        <Cta :data="breadcrumbs" />
      </Spacer>
      <Spacer
        bottom="xxs"
        class="single-header"
      >
        <div>
          <Title
            :data="{
              value: post.title.rendered,
              size: isPost || isEvent ? 'xl' : 'l',
            }"
          />

          <Label
            v-if="post.acf && post.acf.data && isEvent"
            class="meta-event"
            :data="{ value: post.acf.data, size: 'm', tag: 'time' }"
          />
        </div>

        <Cta
          :data="{
            title: 'Condividi',
            fn: openShare,
            size: 'xxs',
          }"
          theme="button"
          class="share"
        />
      </Spacer>
      <RichText
        v-if="post.content"
        class="excerpt"
        :data="{
          value: post.content.rendered,
          size: isPost || isEvent ? 'm' : 'm',
        }"
      />
    </Spacer>

    <Spacer
      v-if="post.acf.section"
      top="xxl"
    />
    <component
      :is="components.get(block.acf_fc_layout)"
      v-for="(block, index) in post.acf.section"
      :key="index"
      :class="['section spacer--padding--b--xl2']"
      :data="block"
    />

    <Spacer
      v-if="archive && !post.acf.section"
      top="xxl"
    />
    <keep-alive>
      <Archive
        v-if="archive && !archive.grouped"
        :key="archiveKey"
        :data="{
          layout: archive.thumb ? archive.thumb : 'thumb_medium',
          categories: archive.categories
            ? `${archive.categories}:${post.id}`
            : null,
          cta: '',
          infinite_scroll: true,
          post_type: archive.post_type ? archive.post_type : 'episode',
          posts_per_scroll: 24,
          thumbs: false,
          title: !archive.grouped
            ? archive.title
              ? archive.title
              : 'Episodi'
            : false,
          groupBy: archive.grouped ? archive.grouped : null,
        }"
      />
    </keep-alive>

    <Grid
      v-if="archive && archive.grouped && archive.guest"
      col="12"
    >
      <Archive
        v-for="group in groups"
        :key="group.id"
        :data="{
          layout: 'thumb_medium',
          categories: `show:${group.id}`,
          cta: {
            title: 'Vai allo show',
            url: group.link,
          },
          posts_per_page: groups.length >= 4 ? 4 : 8,
          post_type: 'episode',
          thumbs: false,
          title: group.title.rendered,
          groups: true,
        }"
      />
      <keep-alive>
        <Archive
          v-if="archive && archive.guest"
          :key="archiveKey"
          :data="{
            layout: archive.thumb ? archive.thumb : 'thumb_medium',
            categories: `guest:${post.id}`,
            cta: '',
            infinite_scroll: true,
            post_type: 'episode',
            posts_per_scroll: 24,
            thumbs: false,
            title: false,
            groupBy: true,
            groups: true,
          }"
        />
      </keep-alive>
    </Grid>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Archive from '@/components/blocks/archive';
import components from '@/assets/js/components';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Figure,
    Cta,
    Title,
    Label,
    RichText,
    Archive,
  },
  mixins: [data],
  data() {
    return {
      components,
      archive: false,
      archiveKey: null,
      groups: null,
    };
  },
  computed: {
    ...mapGetters(['liveStatus', 'postType']),
    isPost() {
      return this.post.type === 'galaxy_express';
    },
    isEvent() {
      return this.post.type === 'event';
    },
    isProject() {
      return this.post.type === 'project';
    },
    breadcrumbs() {
      if (
        this.$route.meta.type === 'galaxy_express'
        || this.$route.meta.type === 'projects'
        || this.$route.meta.type === 'partners'
      ) {
        const url = this.post.link.replace(`${this.$route.params.slug}/`, '');
        let title = this.postType(this.post.type).label;

        if (
          this.$route.params.partner
          && this.post.project_category
          && this.post.project_category.includes(424)
        ) {
          if (this.post.acf && this.post.acf.partner) {
            title = this.post.acf.partner.title.rendered;
          }
        } else if (
          this.$route.params.pathMatch === 'performances'
          || this.$route.params.pathMatch === 'specials'
        ) {
          title = this.$route.params.pathMatch;
        }

        return {
          title,
          url,
        };
      }
      return false;
    },
  },
  created() {
    this.archive = this.$route.meta.archive;
    if (this.archive && this.archive.grouped) {
      this.$store
        .dispatch('getItems', {
          type: 'shows',
          params: {
            types: `${this.archive.categories}:${this.post.id}`,
            per_page: 100,
            offset: 0,
            orderby: 'menu_order',
            lang: this.$store.state.lang,
          },
        })
        .then((res) => {
          this.groups = res;
        });
    }
  },
  beforeDestroy() {
    this.$bus.$emit('removeContentModal', 'share');
  },
  methods: {
    openShare() {
      if (navigator.share) {
        navigator.share({
          title: this.post.title.rendered,
          url: window.location.href,
        });
      } else {
        this.$bus.$emit('modal', {
          type: 'share',
          id: 'share',
          link: window.location.href,
          title: this.post.title.rendered,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 100vw;

  &--project {
    height: 225px;
  }

  @include mq(m) {
    height: calc(var(--vh) * 87.55);
  }

  & > * {
    height: 100%;
  }
}

.meta-event {
  margin-top: var(--spacer-xs);
}

.breadcrumbs {
  text-transform: uppercase;
  color: var(--grey-xd);
  @extend %typo--xs;
}

.single-header {
  display: flex;
  justify-content: space-between;
}

.share {
  text-align: right;
  // flex-shrink: 0;

  ::v-deep {
    button {
      margin-left: auto;
    }
  }
}

.excerpt {
  margin-top: var(--spacer-xs);

  ::v-deep {
    strong {
      display: inline-block;
      margin-bottom: var(--spacer-xs);
      @extend %typo--l;
    }
  }
}

.single--post {
  .section.content {
    ::v-deep .wysiwyg {
      // font-weight: 400;
      span[style="text-decoration: underline;"] {
        float: left;
        min-width: 90px;
        text-decoration: none !important;
        margin-bottom: 1px;
        // float: left;
        // padding-bottom: 1em;
        padding-right: 30px;

        @include mq(m) {
          min-width: 110px;
          padding-right: 40px;
          // padding-right: 80px;
        }
      }
    }
  }
}
</style>
