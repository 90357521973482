var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideHeader),expression:"!$route.meta.hideHeader"}]},[_c('router-link',{staticClass:"logo u-hidden--until-s",attrs:{"to":"/","align":"center","justify":"center"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/svg/logo.svg`)}})],1),_c('Flex',{staticClass:"bar",attrs:{"align":"center","justify":"space-between"}},[_c('Cta',{attrs:{"data":{
        title: _vm.overlayStatus('menu') ? 'Chiudi' : 'Menu',
        size: 'xxs',
        fn: _vm.toggleMenu
      }}}),_c('Cta',{attrs:{"data":{
        title: _vm.overlayStatus('search') ? 'Chiudi' : 'Cerca',
        size: 'xxs',
        fn: _vm.toggleSearch
      }}}),_c('div',{staticClass:"heading"},[_c('Cta',{attrs:{"data":{
          title: 'Radio Raheem',
          size: 's',
          url: '/',
        }}})],1)],1),_c('transition',{attrs:{"css":false},on:{"enter":_vm.enterOverlay,"leave":_vm.leaveOverlay}},[(_vm.overlayStatus('menu') || _vm.overlayStatus('search'))?_c('div',{staticClass:"header-wrapper wrapper"},[_c('transition',{attrs:{"css":false,"mode":"out-in"},on:{"enter":_vm.enterChildOverlay,"leave":_vm.leave}},[_c(_vm.overlayStatus('search') ? 'Search' : 'Menu',{ref:_vm.overlayStatus('search') ? 'search' : 'menu',tag:"component"})],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }