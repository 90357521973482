<template>
  <Grid
    class="notifications"
    row-gap="xs"
    grid="1"
  >
    <Notification
      v-for="notification in custom"
      :key="notification.id"
      :data="notification"
    />
    <Notification
      v-if="pwa && $mq.isIOS && !$mq.isPwa"
      :data="{ ...options.pwa, id: 'pwa', type: 'pwa' }"
    />
    <Newsletter
      v-if="newsletter"
      popup
    />
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import Newsletter from '@/components/ui/newsletter';
import Notification from '@/components/ui/notification';

export default {
  name: 'Notifications',
  components: {
    Newsletter,
    Notification,
  },
  computed: {
    ...mapGetters(['options']),
    newsletter() {
      return this.options.newsletter && this.options.newsletter.active;
    },
    pwa() {
      return this.options.pwa && this.options.pwa.active;
    },
    custom() {
      return this.options.custom;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  bottom: var(--bottom-nl, 0);
  left: 0;
  width: 100%;

  z-index: 20;

  @include mq(s) {
    width: auto;
    left: auto;
    bottom: var(--spacer-xs);
    right: var(--spacer-xs);
  }
}
</style>
