<template>
  <div
    :class="[
      'cta',
      `cta-theme--${theme}`,
      data.size
        ? `typo--${data.size}`
        : theme !== 'default'
          ? 'typo--xxs'
          : undefined,
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn && !data.url">
      <button @click.stop="!disabled ? data.fn() : null">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="modal">
      <button @click.stop="openModal">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="hash">
      <button @click.stop="goTo">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
        :class="[fake ? 'fake-link' : false]"
        @click="data.fn ? data.fn() : null"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        :to="url"
        :class="[fake ? 'fake-link' : false]"
        @click.native="data.fn ? data.fn() : null"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (this.data.url && typeof this.data.url === 'string') {
        return this.$relativeUrl(this.data.url);
      }
      return this.data.url;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    hash() {
      return (
        this.url
        && typeof this.url === 'string'
        && this.url.startsWith('#')
        && !this.url.startsWith('#!')
      );
    },
    fake() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#!')
      );
    },
    modal() {
      return (
        this.url
        && typeof this.url === 'string'
        && this.url.startsWith('#modal-')
      );
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        if (hash.includes('#search-')) {
          this.$store.commit('TOGGLE_OVERLAY', 'search');
          this.$nextTick(() => {
            this.$bus.$emit('openSearch', hash.split('search-')[1]);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;

  &.router-link-active {
    // style
  }
}

.cta-disabled {
  // cursor: progress;
  opacity: 0.5;

  * {
    // cursor: progress !important;
    pointer-events: none !important;
  }
}

button {
  font-size: inherit;
  font-family: inherit;
}

.cta {
  user-select: none;

  a,
  button {
    display: inline-block;

    @include mq($and: $hover) {
      transition: color 0.2s $ease-custom;
      &:hover {
        color: var(--grey-d);
      }
    }
  }
  &-theme {
    // &--default {
    // }
    &--full {
      a,
      button {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    &--button {
      a,
      button {
        display: block;
        text-transform: uppercase;
      }
    }
    &--rounded,
    &--rounded-full {
      a,
      button {
        display: block;
        background: var(--bg, var(--white));
        color: var(--color, var(--black));
        border: 1px solid var(--border, var(--black));
        border-radius: 30px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        padding: 1px 2px 3px 2px;

        @include mq($and: $hover) {
          &:hover {
            background: var(--bg-hover, var(--color, var(--black)));
            color: var(--color-hover, var(--bg, var(--white)));
            border-color: var(--border-hover, var(--border, var(--black)));
          }
        }
      }
    }
    &--rounded {
      a,
      button {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        max-width: 220px;
      }
    }
    &--warning {
      color: var(--warning-color);
    }
    &--login {
      a,
      button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        width: 100%;
        column-gap: var(--spacer-m);
        border-radius: var(--border-radius);
        border: var(--line) solid var(--grey-d);
        padding: var(--spacer-s) var(--spacer-m);
        line-height: normal;

        @include mq($and: $hover) {
          &:hover {
            background: var(--grey-d);
          }
        }
      }
    }
  }
}

.fake-link {
  pointer-events: none;
}
</style>
