<template>
  <div
    :key="onAirKey"
    :class="[
      'on-air',
      overlayStatus('on-air') ? 'on-air--open' : false,
    ]"
  >
    <Flex
      :class="[
        'on-air-header',
        'bar',
        now && now.episode ? 'on-air-with-now' : false,
        now && now.episode && now.episode.gds_featured_image
          ? 'on-air-with-img'
          : false,
      ]"
      align="center"
      justify="space-between"
    >
      <Cta
        :data="{
          title: audio ? 'Pausa' : 'Play',
          fn: toggleRadio,
          toggle: 'circle',
        }"
        theme="button"
        class="on-air-cta on-air-cta-live"
      >
        <template slot="circle">
          <span class="on-air-circle" />
        </template>
      </Cta>

      <Toggle
        v-if="live.video"
        off="v"
        on="a"
        :callback="toggleLive"
        :watch-from-props="liveStatus !== 'video'"
        :initial-state="liveStatus !== 'video'"
        class="on-air-cta on-air-cta-toggle"
      />

      <Flex
        :class="[
          'on-air-title',
          live.video ? 'on-air-title-toggle' : false,
          now || (next && next.length > 0)
            ? 'on-air-title-cta'
            : false,
          audio ? 'on-air-title-playing' : false,
        ]"
        align="center"
      >
        <div @click="toggleOnAir">
          <MarqueeText
            :key="nowTitle"
            :repeat="10"
            :duration="10"
          >
            <Spacer x="xs">
              <Title :data="{ value: nowTitle, size: 's' }" />
            </Spacer>
          </MarqueeText>
        </div>
      </Flex>
    </Flex>

    <Figure
      v-if="now && now.episode && now.episode.gds_featured_image"
      :data="{
        ...now.episode.gds_featured_image,
        ratio: 0.5625,
        align: 'center',
      }"
      disable-caption
      class="thumb-hover"
    />
    <transition
      :css="false"
      @enter="enterOverlay"
      @leave="leaveOverlay"
    >
      <Flex
        v-if="overlayStatus('on-air')"
        class="wrapper"
      >
        <Flex class="wrapper-clip">
          <Spacer class="wrapper-padded">
            <Grid
              grid="2"
              row-gap="xs"
              class="on-air-main"
            >
              <Column size="2">
                <ThumbOnAir
                  v-if="now"
                  :data="now"
                />
                <Spacer bottom="s" />
              </Column>

              <Column
                v-for="set in next"
                :key="set.id"
                size="1"
              >
                <ThumbNext :data="set" />
              </Column>

              <Column size="2">
                <Cta
                  :data="{
                    title: 'Schedule',
                    url: '/schedule',
                  }"
                  :style="{
                    '--bg': 'var(--black)',
                    '--border': 'var(--black)',
                    '--color': 'var(--white)',
                    '--bg-hover': 'var(--black)',
                    '--color-hover': 'var(--white)',
                  }"
                  theme="rounded-full"
                />
              </Column>
            </Grid>
            <Flex
              class="on-air-close"
              justify="center"
              align="center"
            >
              <Cta
                :data="{
                  title: 'Chiudi',
                  fn: toggleOnAir,
                }"
                theme="button"
              />
            </Flex>
          </Spacer>
        </Flex>
      </Flex>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fnSchedule } from '@/assets/js/utils';
import { enterOverlay, leaveOverlay } from '@/assets/js/transitions';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import Toggle from '@/components/ui/toggle';

import ThumbOnAir from '@/components/thumb/on-air';
import ThumbNext from '@/components/thumb/next';
import Figure from '@/components/media/figure';

export default {
  name: 'OnAir',
  components: {
    Cta,
    Title,
    Toggle,
    ThumbOnAir,
    ThumbNext,
    Figure,
  },
  data() {
    return {
      now: null,
      next: null,
      enterOverlay,
      leaveOverlay,
      onAirKey: new Date(
        new Date().toLocaleString('en', {
          timeZone: 'Europe/Rome',
        }),
      ).getTime(),
      onAirInterval: null,
    };
  },
  computed: {
    ...mapGetters([
      'overlayStatus',
      'audio',
      'video',
      'live',
      'liveStatus',
      'scheduleOfDay',
      'options',
    ]),
    nowTitle() {
      return this.now && this.now.titolo
        ? this.now.titolo
        : this.options.label_offline;
    },
  },
  watch: {
    onAirKey: {
      immediate: true,
      handler() {
        if (
          this.scheduleOfDay
          && this.scheduleOfDay.acf
          && this.scheduleOfDay.acf.schedule.length > 0
        ) {
          this.$nextTick(() => {
            const { now, next } = fnSchedule(
              this.scheduleOfDay.acf.schedule,
            );
            this.now = now;
            this.next = next;
            this.$store.commit('SET_NOW', now);
            this.initOnAirInterval();
          });
        }
      },
    },
  },
  beforeDestroy() {
    this.destroyOnAirInterval();
  },
  methods: {
    toggleOnAir() {
      this.$store.commit('TOGGLE_OVERLAY', 'on-air');
    },
    closeOverlay() {
      this.$store.commit('SET_OVERLAY', false);
    },
    toggleRadio() {
      this.$store.commit('SET_AUDIO', !this.audio);
      if (this.audio) {
        this.$store.commit('TOGGLE_LIVE', 'audio');
        if (window.gtag) {
          window.gtag('event', 'play_live_audio', {
            title: this.nowTitle,
          });
        }
      }
    },
    toggleLive(val) {
      if (val) {
        this.$store.commit('TOGGLE_LIVE', 'audio');
      } else {
        this.$store.commit('TOGGLE_LIVE', 'video');
      }
    },
    destroyOnAirInterval() {
      if (this.onAirInterval) {
        window.clearInterval(this.onAirInterval);
      }
    },
    initOnAirInterval() {
      this.destroyOnAirInterval();
      const delta = this.now
        ? this.now.ora_fine
        : this.next && this.next[0]
          ? this.next[0].ora_inizio
          : false;
      if (delta) {
        const endVal = delta === '00:00' ? '24:00' : delta;
        const dayString = this.scheduleOfDay.acf.data;
        const [d, m, y] = dayString.split(', ')[1].split('.');
        const sep = this.$mq.isIOS || this.$mq.isSafari ? '/' : '-';
        const day = `${m}${sep}${d}${sep}20${y}`;
        const end = Date.parse(`${day} ${endVal}`);

        const interval = Math.round(end - this.onAirKey);
        if (interval > 0) {
          this.onAirInterval = setInterval(() => {
            this.onAirKey = new Date(
              new Date().toLocaleString('en', {
                timeZone: 'Europe/Rome',
              }),
            ).getTime();

            this.destroyOnAirInterval();
            this.initOnAirInterval();
          }, interval);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.on-air {
  position: relative;
  background: var(--grey);
  overflow: hidden;

  &-header {
    z-index: 2;
    user-select: none;

    & > * {
      position: relative;
      transform: translateY(-2px);

      @include mq(s) {
        transform: translateY(-1px);
      }
    }
  }

  &-close {
    position: sticky;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
    background: var(--grey);
    padding: 4px 5px 6px;
    text-align: center;
    height: var(--bar-height);
    border-top: 1px solid transparent;

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -10px;
      width: calc(100% + 20px);
      height: 1px;
      background: var(--grey-d);
    }
  }

  .thumb-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    pointer-events: none;
  }
  @include mq($and: $hover) {
    .on-air-header.on-air-with-img:hover {
      mix-blend-mode: difference;
      color: var(--white);

      & + .thumb-hover {
        display: block;
      }

      ::v-deep {
        .slider {
          border-color: var(--white);
        }
      }
    }
  }

  &--open {
    overflow-y: auto;

    .on-air-header.on-air-with-now {
      mix-blend-mode: difference;
      color: var(--white);

      ::v-deep {
        .slider {
          border-color: var(--white);
        }
      }

      .thumb-hover {
        display: none;
      }
    }
  }

  &-cta {
    position: relative;
    z-index: 1;

    &-toggle {
      transform: translateY(0px);
    }
  }

  &-main {
    min-height: calc(100% - var(--bar-height));
    align-content: flex-start;
    padding-bottom: var(--spacer-xs);
  }

  &-title {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 0px 0 65px;
    top: 0;

    &-cta {
      & > div {
        cursor: pointer;
      }
    }

    &-toggle {
      padding: 0 47px 0 65px;
    }

    &-playing {
      padding-left: 75px;
    }

    & > * {
      width: 100%;
      text-align: center;
    }

    ::v-deep {
      .marquee-text-wrap {
        height: var(--bar-height);
      }
      .marquee-text-content {
        height: var(--bar-height);
      }

      .title {
        line-height: var(--bar-height);
      }
    }
  }

  .on-air-cta-live {
    padding-left: 15px;
  }

  .on-air-circle {
    position: absolute;
    left: 0;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--red);

    margin-top: -3px;

    animation: live 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }

  @keyframes live {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0.8);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
}
</style>
