var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[(_vm.theme !== 'about')?_c('Spacer',{attrs:{"top":_vm.liveStatus !== 'video'
        ? _vm.checkArchiveSection
          ? { default: 'xs', s: '0' }
          : { default: 'xs', s: 'xxl' }
        : 's'}}):_vm._e(),_vm._l((_vm.post.acf.section),function(block,index){return _c(_vm.components.get(block.acf_fc_layout),{key:index,tag:"component",class:[
      _vm.theme !== 'about'
        ? block.theme && (block.theme === 'hero' || block.theme === 'banner')
          ? 'spacer--padding--b--xxl'
          : 'spacer--padding--b--l'
        : 'spacer--padding--t--s spacer--padding--t-s--xxl',
    ],attrs:{"data":block}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }