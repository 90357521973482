var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cta',
    `cta-theme--${_vm.theme}`,
    _vm.data.size
      ? `typo--${_vm.data.size}`
      : _vm.theme !== 'default'
        ? 'typo--xxs'
        : undefined,
    _vm.disabled ? 'cta-disabled' : null,
  ]},[(_vm.data.fn && !_vm.data.url)?[_c('button',{on:{"click":function($event){$event.stopPropagation();!_vm.disabled ? _vm.data.fn() : null}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.modal)?[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.openModal.apply(null, arguments)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.hash)?[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.goTo.apply(null, arguments)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.external)?[_c('a',{class:[_vm.fake ? 'fake-link' : false],attrs:{"href":_vm.data.url,"target":_vm.data.target},on:{"click":function($event){_vm.data.fn ? _vm.data.fn() : null}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:[_c('router-link',{class:[_vm.fake ? 'fake-link' : false],attrs:{"to":_vm.url},nativeOn:{"click":function($event){_vm.data.fn ? _vm.data.fn() : null}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }